import React, {useState, useEffect, useRef} from 'react'
import {makeStyles} from '@mui/styles'
import {Typography, Colors, Spacing} from '../../assets/styles'
import ResendCodeModal from './ResendCodeModal'
import AppAuth from '../../lib/AppAuth'
import NumberBox from './NumberBox'
import {formatPhoneNumber} from 'react-phone-number-input'

const CODE_LENGTH = 6

const useStyles = makeStyles(theme => ({
  verifyComponent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  verifyHeader: {
    marginTop: Spacing.spaceLarge,
    textAlign: 'center',
    fontSize: Typography.fontSizeMedium,
    marginBottom: Spacing.spaceSmall,
  },
  verifyInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: Spacing.spaceExtraLarge,
  },
  smallBoxesContainer: {
    display: 'flex',
  },
  resendCodeCopy: {
    marginTop: Spacing.spaceMedium,
    display: 'flex',
    justifyContent: 'center',
  },
  resendCodeButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  hiddenInput: {
    display: 'none',
  },
}))

const Verify = ({hideResendPrompt, onCodeSubmit, phoneNumber, resettingPassword}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [showResendCodeModal, setShowResendCodeModal] = useState(false)
  const [smallBoxes, setSmallBoxes] = useState(Array(CODE_LENGTH).fill(''))
  const boxRefs = useRef([])

  const formattedNumber = formatPhoneNumber(phoneNumber)

  const handleSubmit = async () => {
    const loginNum = phoneNumber
    if (resettingPassword) {
      await AppAuth.Instance().forgotPassword(loginNum)
    } else {
      await AppAuth.Instance().resendSignUp(loginNum)
    }
  }

  const handleInput = (numberInput, index) => {
    // Strip nondigits and update the specific small box
    let updatedNumber = numberInput.replace(/[^0-9]/g, '')

    if (updatedNumber.length > 1) {
      updatedNumber = updatedNumber.slice(0, 1)
    }

    let newSmallBoxes = [...smallBoxes]
    newSmallBoxes[index] = updatedNumber
    setSmallBoxes(newSmallBoxes)

    // If this box was filled and there's another one, focus the next one
    if (updatedNumber && index < CODE_LENGTH - 1) {
      boxRefs.current[index + 1].focus()
    }

    // Update the whole number
    let newNumber = newSmallBoxes.join('')
    setValue(newNumber)
  }

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !smallBoxes[index] && index > 0) {
      boxRefs.current[index - 1].focus()
    }
  }

  useEffect(() => {
    if (value.length === CODE_LENGTH) {
      onCodeSubmit(value)
    }
  }, [value])

  return (
    <React.Fragment>
      <div className={classes.verifyComponent}>
        <p className={classes.verifyHeader}>A code was sent to{'\n' + formattedNumber}</p>
        <div className={classes.verifyInputContainer}>
          <div className={classes.smallBoxesContainer}>
            {smallBoxes.map((box, index) => (
              <NumberBox
                autoFocus={index === 0}
                value={box}
                ref={ref => (boxRefs.current[index] = ref)}
                onChange={event => handleInput(event.target.value, index)}
                onKeyDown={event => handleKeyDown(event, index)}
                key={index}
              />
            ))}
          </div>
          {!hideResendPrompt && (
            <React.Fragment>
              <div className={classes.resendCodeCopy}>
                <p>Not receiving the code?</p>
              </div>
              <div className={classes.resendCodeButton}>
                <p style={{color: Colors.rxrTealColor, cursor: 'pointer'}} onClick={() => setShowResendCodeModal(true)}>
                  Resend
                </p>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <ResendCodeModal isOpen={showResendCodeModal} handleClose={() => setShowResendCodeModal(false)} handleSubmit={handleSubmit} />
    </React.Fragment>
  )
}

export default Verify
