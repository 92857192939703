import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {Colors} from '../assets/styles/index'
import RXRIcon from './RXRIcon'
import {DateTimePicker} from '@mui/x-date-pickers'
import {INPUT_STYLES} from '../assets/styles/textfield'
import SimpleSpinner from './SimpleSpinner'

const useStyles = makeStyles(theme => ({
  dateContainer: {
    ...INPUT_STYLES.root,
  },
}))

function DateTimeInput(props) {
  const classes = useStyles()
  const label = props.isRequired ? `${props.label} *` : props.label
  const calendarColor = props.error ? Colors.rxrRedColor : props.disabled ? Colors.rxrDarkGreyColor : Colors.rxrBlueColor

  const onChangeHandler = dateSelected => {
    props.onChange(dateSelected)
  }

  return (
    <DateTimePicker
      className={[classes.dateContainer, props.className || ''].join(' ')}
      slotProps={{
        textField: {
          error: props.error,
          placeholder: ' ',
          helperText: props.helperText,
        },
      }}
      slots={{
        openPickerIcon: props.isLoading
          ? SimpleSpinner
          : () => <RXRIcon size={RXRIcon.SIZE_LARGE} icon={RXRIcon.CALENDAR} color={calendarColor} />,
      }}
      label={label}
      minDate={props.min}
      maxDate={props.max}
      value={props.value ? new Date(props.value) : props.value}
      onAccept={onChangeHandler}
      disabled={props.disabled}
      format="MM/dd/yyyy, hh:mm a"
      keyboardIcon={<RXRIcon icon={RXRIcon.CALENDAR} size={RXRIcon.SIZE_LARGE} color={calendarColor} />}
      fullWidth
    />
  )
}

DateTimeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
}

export default DateTimeInput
