import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class VendorService extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getVendorService(input, options) {
    return this._get(
       'getVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getVendorService(input, options) {
    return new this().getVendorService(input, options)
  }

  /**
   * @param { {filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorServices(input, options) {
    return this._list(
       'listVendorServices',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorServices(input, options) {
    return new this().listVendorServices(input, options)
  }

  /**
   * @param { {filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorServices(input, options) {
    return this._iterate(
       'listVendorServices',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorServices(input, options) {
    return new this().iterateVendorServices(input, options)
  }

  /**
   * @param { {foreignServiceId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorServicesByForeignService(input, options) {
    return this._list(
       'listVendorServicesByForeignService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {foreignServiceId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorServicesByForeignService(input, options) {
    return new this().listVendorServicesByForeignService(input, options)
  }

  /**
   * @param { {foreignServiceId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorServicesByForeignService(input, options) {
    return this._iterate(
       'listVendorServicesByForeignService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {foreignServiceId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorServicesByForeignService(input, options) {
    return new this().iterateVendorServicesByForeignService(input, options)
  }

  /**
   * @param { {buildingId?: ID, typeSubType?: ModelVendorServiceByBuildingCompositeKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorServicesByBuilding(input, options) {
    return this._list(
       'listVendorServicesByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, typeSubType?: ModelVendorServiceByBuildingCompositeKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorServicesByBuilding(input, options) {
    return new this().listVendorServicesByBuilding(input, options)
  }

  /**
   * @param { {buildingId?: ID, typeSubType?: ModelVendorServiceByBuildingCompositeKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorServicesByBuilding(input, options) {
    return this._iterate(
       'listVendorServicesByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, typeSubType?: ModelVendorServiceByBuildingCompositeKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorServiceFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorServicesByBuilding(input, options) {
    return new this().iterateVendorServicesByBuilding(input, options)
  }

  /**
   * @param { {input: CreateVendorServiceInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createVendorService(input, options) {
    return this._mutate(
       'createVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateVendorServiceInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createVendorService(input, options) {
    return new this().createVendorService(input, options)
  }

  /**
   * @param { {input: UpdateVendorServiceInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateVendorService(input, options) {
    return this._mutate(
       'updateVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateVendorServiceInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateVendorService(input, options) {
    return new this().updateVendorService(input, options)
  }

  /**
   * @param { {input: DeleteVendorServiceInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteVendorService(input, options) {
    return this._mutate(
       'deleteVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteVendorServiceInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteVendorService(input, options) {
    return new this().deleteVendorService(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "VendorService"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    Object.entries(VendorService._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = VendorService.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })


    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof VendorService.custom_afterFind === 'function') {
      return VendorService.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { VendorService }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
VendorService._complexFields = {"building":"Building","addOnOptions":"[VendorServiceAddOn]","details":"VendorServiceDetails"}
VendorService._jsonFields = []

VendorService.FragmentDefault = `
fragment VendorServiceFragmentDefault on VendorService {
  id
  foreignServiceId
  buildingId
  label
  type
  subType
  price
  duration
  amenityId
  order
  createdAt
  updatedAt
}

`


export default VendorService
