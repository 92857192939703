import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import {Colors} from '../assets/styles'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import {FormControlLabel} from '@mui/material'
import {ellipsis} from '../assets/styles/typography'

const rootStyle = {
  ...Colors.black,
  padding: '6px',
  '&$checked': {
    color: Colors.rxrBlueColor,
  },
  '&$disabled': {
    ...Colors.darkGrey,
  },
}

const useStyles = makeStyles(() => ({
  label: props => ({
    width: '100%',
    margin: 0,
    '& .MuiFormControlLabel-label': props.ellipsis ? ellipsis : undefined,
  }),
  root: {
    ...rootStyle,
  },
  rootWithHover: {
    ...rootStyle,
    '&:hover': {
      boxShadow: `inset 0 0 0 9.25px ${Colors.rxrLightTeal}`,
      backgroundClip: 'content-box',
    },
  },
  checked: {},
  disabled: {},
}))

const CustomCheckbox = props => {
  const classes = useStyles({ellipsis: !!props.ellipsis})
  return (
    <FormControlLabel
      className={classes.label}
      control={
        <Checkbox
          classes={{
            root: props.hasHoverEffect ? classes.rootWithHover : classes.root,
            checked: classes.checked,
            disabled: classes.disabled,
          }}
          inputProps={{'aria-label': 'uncontrolled-checkbox'}}
          checked={props.isChecked}
          indeterminate={props.isIndeterminate}
          onChange={e => {
            if (typeof props.onChange !== 'function') {
              return
            }
            props.onChange(e.target.checked, e)
          }}
          onClick={e => e.stopPropagation()}
          name={props.name}
          disabled={props.isDisabled}
        />
      }
      label={props.label || ''}
    />
  )
}

CustomCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  ellipsis: PropTypes.bool,
  label: PropTypes.string,
  isIndeterminate: PropTypes.bool,
  hasHoverEffect: PropTypes.bool,
}

export default CustomCheckbox
