import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {makeStyles} from '@mui/styles'
import useBuildingColors from '../../hooks/useBuildingColors'
import {Spacing, Colors} from '../../assets/styles'
import BuildingSelector from '../BuildingSelector'

function SelectedBuildingsLegend(props) {
  const classes = useStyles()
  const getBuildingColor = useBuildingColors()
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)

  return (
    <BuildingSelector selected={props.selected} onChange={props.onChange}>
      <div className={props.className}>
        Showing:{' '}
        {props.selected.map(bId => {
          return (
            <span key={bId} className={classes.buildingLabel}>
              <span className={classes.buildingCircle} style={{backgroundColor: getBuildingColor(bId)}}></span>
              <span className={classes.buildingName}>{buildingsLookup[bId].displayName}</span>
            </span>
          )
        })}
      </div>
    </BuildingSelector>
  )
}

const useStyles = makeStyles(theme => ({
  buildingLabel: {
    display: 'inline-block',
    marginRight: Spacing.spaceSmall,
  },
  buildingName: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: Colors.rxrTealColor,
  },
  buildingCircle: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: Spacing.spaceExtraSmall,
  },
}))

SelectedBuildingsLegend.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SelectedBuildingsLegend
