import ActionTypes from '../actions/types'
import {CACHE_NanPanelOpenStatus} from '../constants/AppConstants'

const isNavPanelStartingOpen =
  typeof localStorage[CACHE_NanPanelOpenStatus] === 'string' ? !!parseInt(localStorage[CACHE_NanPanelOpenStatus]) : true

// This is a hacky first pass at supporting Mobile views
const isMobile = window.innerWidth < 900

const navOpenWidth = isMobile ? 0 : 250
const navClosedWidth = isMobile ? 0 : 72

const initialState = {
  isNavPanelOpen: isMobile ? false : isNavPanelStartingOpen,
  navPanelWidth: isNavPanelStartingOpen ? navOpenWidth : navClosedWidth,
  isMobile: isMobile,
}

// --------------------------------------------------------------------------------
// State mutation functions:

function setNavPanelOpenState(state, isOpen) {
  localStorage[CACHE_NanPanelOpenStatus] = isOpen ? 1 : 0
  return {...state, isNavPanelOpen: isOpen, navPanelWidth: isOpen ? navOpenWidth : navClosedWidth}
}

// --------------------------------------------------------------------------------

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_SET_NAV_PANEL_OPEN:
      return setNavPanelOpenState(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
