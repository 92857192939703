/**
 * @type {Object<string, string>}
 */
const fadeColorCache = {}

/**
 * @param {string} hex
 * @param {number} opacity
 * @returns {string}
 */
export function fadeColor(hex, opacity) {
  const cacheKey = hex + opacity

  if (!fadeColorCache[cacheKey]) {
    if (opacity < 0) opacity = 0
    if (opacity > 1) opacity = 1

    hex = hex.replace('#', '')

    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)

    fadeColorCache[cacheKey] = `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  return fadeColorCache[cacheKey]
}
