import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import CalendarWithTooltip from './CalendarWithTooltip'
import moment from 'moment/moment'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import {Colors, Spacing} from '../../assets/styles'
import {v4 as uuid} from 'uuid'
import useSharedCalendarLogic from '../../hooks/useSharedCalendarLogic'
import useServiceAvailabilityContext from '../../hooks/useServiceAvailabilityContext'
import EventMenuRenderer from './EventMenuRenderer'

function WeekCalendar(props) {
  const classes = useStyles()
  const {buildingIds, closuresLookup, generalCalendarAvailability} = useServiceAvailabilityContext()
  const [closedEvents, setClosedEvents] = useState([])
  const servicesLookup = useSelector(state => state.User.servicesLookup)

  const startOfWeek = moment(props.date).startOf('week')
  const endOfWeek = moment(props.date).endOf('week')
  // columns are always the days of the week starting with Sunday ("Sun")
  const columns = [...new Array(7)].map((e, i) => moment(startOfWeek).add(i, 'days').format('ddd'))

  useEffect(() => {
    // TODO: This expects only 1 GA per day of week. If we ever have it split into multiple (e.g., 9-12, 1-5), this will need to be updated

    // the closed events are the times that all buildings are closed on a given day within the period defined by calendarBounds
    setClosedEvents(
      generalCalendarAvailability.byDayOfWeekStartAndEnd.reduce((acc, dayBounds, dow) => {
        const closedEventDefaults = {
          color: Colors.rxrMediumLightGreyColor,
          column: columns[dow],
          content: 'Closed',
          isDisabled: true,
        }

        if (dayBounds.start > dayBounds.end) {
          // this happens when there are no general availability settings for this day -- therefore the closure should be the entire day
          acc.push({
            ...closedEventDefaults,
            id: `closed-complete-${uuid()}`,
            start: generalCalendarAvailability.start,
            end: generalCalendarAvailability.end,
          })
        } else {
          if (dayBounds.start > generalCalendarAvailability.start) {
            acc.push({
              ...closedEventDefaults,
              id: `closed-start-${uuid()}`,
              start: dayBounds.start,
              end: generalCalendarAvailability.start,
            })
          }
          if (dayBounds.end < generalCalendarAvailability.end) {
            acc.push({
              ...closedEventDefaults,
              id: `closed-end-${uuid()}`,
              start: dayBounds.end,
              end: generalCalendarAvailability.end,
            })
          }
        }

        return acc
      }, []),
    )
  }, [generalCalendarAvailability])

  const getAppointmentContent = appointment => {
    const service = servicesLookup[appointment.vendorServiceId]
    return (
      <div className={classes.eventWrapper}>
        <strong>{service.label}</strong>
        <p>{appointment.resident.unitNumber}</p>
        <p>{appointment.resident.displayName}</p>
      </div>
    )
  }

  const getClosureContent = closure => {
    return (
      <div className={classes.eventWrapper}>
        <strong>{closure.label}</strong>
      </div>
    )
  }

  const {
    closureEvents,
    events,
    editingEvent,
    editingClosure,

    handleUpdateClosure,
    handleUpdateAppointment,
    resetAppointmentEvents,
    resetClosureEvents,
  } = useSharedCalendarLogic({
    getColumnStartAndEndTimes: getColumnStartAndEndTimes,
    columns: columns,
    getAppointmentContent: getAppointmentContent,
    getClosureContent: getClosureContent,
  })

  /**
   * @param {CalendarEventConfig} ev
   */
  function eventToClosure(ev) {
    const startAt = moment(startOfWeek).add(columns.indexOf(ev.column), 'days').startOf('day').add(ev.start, 'minutes').toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    return {startAt: startAt, endAt: endAt}
  }

  const isCurrentWeek = moment() >= startOfWeek && moment() <= endOfWeek

  const filteredClosureEvents = closureEvents.filter(
    cEv =>
      (moment(cEv.startAt).isBetween(startOfWeek, endOfWeek) || moment(cEv.endAt).isBetween(startOfWeek, endOfWeek)) &&
      buildingIds.some(b => closuresLookup[cEv.id] && closuresLookup[cEv.id].buildingIds.includes(b)),
  )

  return (
    <CalendarWithTooltip
      eventToObject={eventToClosure}
      columns={columns}
      events={events.concat(closedEvents).concat(filteredClosureEvents)}
      highlightedColumn={isCurrentWeek ? moment().format('ddd') : null}
      calendarStartTime={generalCalendarAvailability.start}
      calendarEndTime={generalCalendarAvailability.end}
      newAppointmentText={'New time block off'}
      disableEventDragging={true}
      renderEventMenu={(obj, id, onUpdateEvent, onCloseEvent) => (
        <EventMenuRenderer
          obj={obj}
          id={id}
          onUpdateEvent={onUpdateEvent}
          onCloseEvent={onCloseEvent}
          editingEvent={editingEvent}
          editingClosure={editingClosure}
          handleUpdateClosure={handleUpdateClosure}
          handleUpdateAppointment={handleUpdateAppointment}
          resetAppointmentEvents={resetAppointmentEvents}
          resetClosureEvents={resetClosureEvents}
          getColumnStartAndEndTimes={getColumnStartAndEndTimes}
        />
      )}
    />
  )
}

export const useStyles = makeStyles(theme => ({
  eventWrapper: {
    padding: Spacing.spaceSmall,
    textAlign: 'center',
    '& strong': {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
    '& p:last-child': {
      fontSize: '0.8em',
      fontStyle: 'italic',
    },
  },
}))

WeekCalendar.propTypes = {
  date: PropTypes.object.isRequired,
}

export default WeekCalendar

/**
 * @param {{startAt: Date|string, endAt: Date|string}} r
 * @return {{column: string, start: number, end: number}}
 */
function getColumnStartAndEndTimes(r) {
  const startOfDay = moment(r.startAt).startOf('day')
  return {
    column: moment(r.startAt).format('ddd'),
    start: moment(r.startAt).diff(startOfDay, 'minutes'),
    end: moment(r.endAt).diff(startOfDay, 'minutes'),
  }
}
