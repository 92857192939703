import GraphQLHelper from '../../orm/GQLQueryHelper'
import gql from 'graphql-tag'
import moment from 'moment'

/**
 * @param {string} serviceId
 * @param {Date} date
 * @returns {Promise<*>}
 */
export async function loadAvailability(serviceId, date) {
  const res = await GraphQLHelper.Instance().queryOnce(
    gql`
      query GetVendorServiceAvailability($input: GetVendorServiceAvailabilityInput!) {
        getVendorServiceAvailability(input: $input) {
          availability {
            start
            end
          }
        }
      }
    `,
    {
      input: {
        vendorServiceId: serviceId,
        startAt: moment(date).startOf('day').toISOString(),
        endAt: moment(date).endOf('day').toISOString(),
      },
    },
  )

  return res.availability
}

/**
 * @param {{
 *   id: string,
 *   startAt: Date,
 *   endAt: Date,
 * }} input
 * @returns {Promise<{id: string}>}
 */
export async function rescheduleExistingAppointment(input) {
  return await GraphQLHelper.Instance().executeMutation(
    gql`
      mutation RescheduleExistingVendorAppointment($input: RescheduleVendorAppointmentInput!) {
        rescheduleExistingVendorAppointment(input: $input) {
          id
        }
      }
    `,
    {
      input: {
        vendorAppointmentId: input.id,
        startAt: input.startAt.toISOString(),
        endAt: input.endAt.toISOString(),
      },
    },
  )
}

/**
 * @param {string} vendorAppointmentId
 * @returns {Promise<*>}
 */
export async function cancelExistingAppointment(vendorAppointmentId) {
  return await GraphQLHelper.Instance().executeMutation(
    gql`
      mutation CancelExistingVendorAppointment($input: CancelVendorAppointmentInput!) {
        cancelExistingVendorAppointment(input: $input) {
          id
        }
      }
    `,
    {
      input: {
        vendorAppointmentId: vendorAppointmentId,
      },
    },
  )
}

/** @typedef {Object} VendorServiceResourceSettings
 * @property {string} id
 * @property {string} foreignServiceId
 * @property {Array<ResourceClosure>} closures
 * @property {Array<ResourceGeneralAvailability>} generalAvailability
 */

/** @typedef {Object} ResourceClosure
 * @property {string} id
 * @property {string} label
 * @property {Date} startAt
 * @property {Date} endAt
 */

/** @typedef {Object} ResourceGeneralAvailability
 * @property {string} id
 * @property {number} dayOfWeek
 * @property {string} startTime
 * @property {string} endTime
 */

/**
 * @returns {Promise<VendorServiceResourceSettings>}
 */
export async function loadVendorServiceResourceSettings(id) {
  const res = await GraphQLHelper.Instance().queryOnce(
    gql`
      query GetVendorServiceResourceSettings($input: CommonRequestInput!) {
        getVendorServiceResourceSettings(input: $input) {
          id
          foreignServiceId
          closures {
            id
            label
            startAt
            endAt
          }
          generalAvailability {
            id
            dayOfWeek
            startTime
            endTime
          }
        }
      }
    `,
    {
      input: {
        id: id,
      },
    },
  )

  return res
}

/**
 * @param {VendorServiceResourceSettings} input
 * @returns {Promise<VendorServiceResourceSettings>}
 */
export async function updateVendorServiceResourceSettings(input) {
  const i = {...input}
  delete i.foreignServiceId

  return await GraphQLHelper.Instance().executeMutation(
    gql`
      mutation UpdateVendorServiceResourceSettings($input: UpdateVendorServiceResourceSettingsInput!) {
        updateVendorServiceResourceSettings(input: $input) {
          id
          foreignServiceId
          closures {
            id
            label
            startAt
            endAt
          }
          generalAvailability {
            id
            dayOfWeek
            startTime
            endTime
          }
        }
      }
    `,
    {
      input: i,
    },
  )
}
