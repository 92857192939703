// this hook is used because vendor dashboard was built using react-router-dom^6, but it using dependency from rxo dashboard
// which uses react-router-dom^5

import {useHistory, useLocation} from 'react-router-dom'

/**
 * @returns {function(string, Object?):void}
 */
function useNavigateShim() {
  const history = useHistory()
  const location = useLocation()

  return (to, params) => {
    // Check if the current path is the same as the target path
    if (location.pathname === to) {
      history.replace(to, params)
    } else {
      history.push(to, params)
    }
  }
}

export default useNavigateShim
