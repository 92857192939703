import React from 'react'
import {makeStyles} from '@mui/styles'
import ButtonLoader from './../assets/images/Icon/ButtonLoader.svg'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

const RXRButtonLoader = ({className, ...props}) => {
  const classes = useStyles(props)
  const styleProps = {
    width: props.imgWidth || '33',
    height: props.imgHeight || '33',
  }
  return (
    <div className={[classes.root, className].join(' ')}>
      <img alt="Loading icon" src={ButtonLoader} style={styleProps} />
    </div>
  )
}

export default RXRButtonLoader
