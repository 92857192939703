import React from 'react'
import PropTypes from 'prop-types'

const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')

function HighlightText({className, search = '', children = ''}) {
  const patt = new RegExp(`(${escapeRegExp(search)})`, 'i')
  const parts = String(children).split(patt)

  return (
    <span className={className}>
      {search ? parts.map((part, index) => (patt.test(part) ? <b key={index}>{part}</b> : part)) : children}
    </span>
  )
}

HighlightText.propTypes = {
  search: PropTypes.string,
  className: PropTypes.any,
}

export default HighlightText
