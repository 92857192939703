import React from 'react'
import {makeStyles} from '@mui/styles'
import useDraggable from '../hooks/useDraggable'
import {Spacing} from '../../assets/styles'
import PropTypes from 'prop-types'

function CalendarEventDragHandle(props) {
  const classes = useStyles()
  const {isDragging, ref} = useDraggable(props.onDrag)
  return (
    <div className={[classes.handle, isDragging ? 'dragging' : ''].join(' ')} ref={ref}>
      <div />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  handle: {
    cursor: 'grab',
    width: '100%',
    padding: Spacing.spaceExtraSmall,
    backgroundColor: 'rgba(0,0,0,0.1)',

    '&.dragging': {
      cursor: 'grabbing',
    },

    '& div': {
      width: '50%',
      margin: '0 auto',
      height: Spacing.spaceExtraExtraSmall,
      borderTop: `1px solid rgba(0,0,0,0.3)`,
      borderBottom: `1px solid rgba(0,0,0,0.3)`,
    },
  },
}))

CalendarEventDragHandle.propTypes = {
  onDrag: PropTypes.func.isRequired,
}

export default CalendarEventDragHandle
