import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../assets/styles/layout'
import PageHeader from '../PageHeader'
import {Grid} from '@mui/material'
import {AntTab, AntTabs} from '../AntTabs'
import {makeStyles} from '@mui/styles'
import {rxrContainerColor} from '../../assets/styles/color'
import ClearableInput from '../ClearableInput'
import VendorBookingsTable from './VendorBookingsTable'
import {statusIsPast} from '../../lib/utilities'
import {TAB_UPCOMING, TAB_PAST, NO_UPCOMING_BOOKINGS, NO_PAST_BOOKINGS} from '../../constants/VendorBookingConstants'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import RXRIcon from '../RXRIcon'
import LoadingStateIconAndMessage from '../LoadingStateIconAndMessage'
import {useSelector} from 'react-redux'
import {LOADING_STATE_ERROR, LOADING_STATE_LOADING} from '../../constants/AppConstants'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: rxrContainerColor,
    flex: 1,
    width: '100%',
    overflowX: 'auto',
  },
}))

function VendorBookings() {
  const classes = useStyles()
  const appointmentIds = useSelector(state => state.Appointments.appointmentIds)
  const appointmentsLookup = useSelector(state => state.Appointments.appointmentsLookup)
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)
  const loadingStatus = useSelector(state => state.Appointments.loading.status)
  const [upcomingBookings, setUpcomingBookings] = useState([])
  const [pastBookings, setPastBookings] = useState([])
  const [tab, setTab] = useState(TAB_UPCOMING)
  const [filterTerm, setFilterTerm] = useState('')
  const servicesLookup = useSelector(state => state.User.servicesLookup)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const upcoming = []
    const past = []
    for (const id of appointmentIds) {
      const appointment = appointmentsLookup[id]
      if (statusIsPast(appointment.status)) {
        past.push(appointment)
      } else {
        upcoming.push(appointment)
      }
    }
    setUpcomingBookings(upcoming)
    setPastBookings(past)
  }, [appointmentIds, appointmentsLookup])

  let filteredAppointments = tab === TAB_UPCOMING ? upcomingBookings : pastBookings
  if (filterTerm) {
    const formattedTerm = filterTerm.toLowerCase()
    filteredAppointments = filteredAppointments.filter(g => {
      return (
        (buildingsLookup[g.buildingId] && buildingsLookup[g.buildingId].displayName.toLowerCase().includes(formattedTerm)) ||
        (g.resident &&
          (g.resident.displayName.toLowerCase().includes(formattedTerm) || g.resident.unitNumber.toLowerCase().includes(formattedTerm))) ||
        servicesLookup[g.vendorServiceId].label.toLowerCase().includes(formattedTerm) ||
        servicesLookup[g.vendorServiceId].bedLabel.toLowerCase().includes(formattedTerm)
      )
    })
  }
  let visibleTableComponent
  if (loadingStatus === LOADING_STATE_LOADING) {
    visibleTableComponent = <LoadingStateIconAndMessage message={'Loading vendor bookings...'} />
  } else if (loadingStatus === LOADING_STATE_ERROR) {
    visibleTableComponent = <EmptyStateIconAndMessage message={'Error loading appointments, please try again.'} icon={RXRIcon.CLOSE} />
  } else if (tab === TAB_UPCOMING) {
    if (filteredAppointments.length === 0) {
      visibleTableComponent = <EmptyStateIconAndMessage message={NO_UPCOMING_BOOKINGS} icon={RXRIcon.CLEANING} />
    } else {
      visibleTableComponent = (
        <VendorBookingsTable vendorBookings={filteredAppointments} filterTerm={filterTerm} page={page} setPage={setPage} />
      )
    }
  } else {
    if (filteredAppointments.length === 0) {
      visibleTableComponent = <EmptyStateIconAndMessage message={NO_PAST_BOOKINGS} icon={RXRIcon.CLEANING} />
    } else {
      visibleTableComponent = (
        <VendorBookingsTable vendorBookings={filteredAppointments} filterTerm={filterTerm} page={page} setPage={setPage} />
      )
    }
  }

  return (
    <div style={PageContainer}>
      <PageHeader title={'Bookings'}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} md={6}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={'Search by Location, Resident, Unit, or Service'} />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={6} md={6}>
            <AntTabs
              value={tab}
              onChange={(e, t) => {
                setTab(t)
                setPage(1)
              }}
            >
              <AntTab label={'Upcoming bookings'} />
              <AntTab label={'Past bookings'} />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>
      <div className={classes.mainContainer}>
        <div style={{height: '100%'}}>{visibleTableComponent}</div>
      </div>
    </div>
  )
}
export default VendorBookings
