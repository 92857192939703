import ORM from '../../orm/collections'
import ActionTypes from './types'
import {LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING} from '../constants/AppConstants'

/**
 * @param {Dispatch} dispatch
 * @param {Array<string>} buildingIds
 * @returns {Promise<Array<*>>}
 */
export async function loadVendorAppointments(dispatch, buildingIds) {
  dispatch({
    type: ActionTypes.VENDOR_APPOINTMENTS_LOADING_STATUS,
    payload: {
      status: LOADING_STATE_LOADING,
      meta: new Date().toISOString(),
    },
  })

  try {
    let allAppointments = []
    for (let buildingId of buildingIds) {
      const appointments = await ORM.VendorAppointment.as(ORM.VendorAppointment.WithResident).listVendorAppointmentsByBuilding({
        buildingId: buildingId,
        filter: {
          status: {ne: 'NEEDS_RESCHEDULE'},
        },
      })
      allAppointments = allAppointments.concat(appointments)
    }

    dispatch({
      type: ActionTypes.VENDOR_APPOINTMENTS_LOADED,
      payload: allAppointments,
    })

    dispatch({
      type: ActionTypes.VENDOR_APPOINTMENTS_LOADING_STATUS,
      payload: {
        status: LOADING_STATE_LOADED,
        meta: new Date().toISOString(),
      },
    })

    return allAppointments
  } catch (error) {
    dispatch({
      type: ActionTypes.VENDOR_APPOINTMENTS_LOADING_STATUS,
      payload: {
        status: LOADING_STATE_ERROR,
        meta: error.messages,
      },
    })

    throw error
  }
}

export async function refreshAppointment(dispatch, appointmentId) {
  const appointment = await ORM.VendorAppointment.as(ORM.VendorAppointment.WithResident).getVendorAppointment({id: appointmentId})

  dispatch({
    type: ActionTypes.VENDOR_APPOINTMENT_UPDATE,
    payload: appointment,
  })
}
