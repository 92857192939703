import React, {useEffect, useState} from 'react'
import CalendarWithTooltip from './CalendarWithTooltip'
import moment from 'moment'
import {useSelector} from 'react-redux'
import EventMenuRenderer from './EventMenuRenderer'
import useServiceAvailabilityContext from '../../hooks/useServiceAvailabilityContext'
import {Colors} from '../../assets/styles'
import {v4 as uuid} from 'uuid'
import useSharedCalendarLogic from '../../hooks/useSharedCalendarLogic'
import {useStyles} from './WeekCalendar'

function DayCalendar(props) {
  const classes = useStyles()
  const {buildingIds, closuresLookup, generalCalendarAvailability} = useServiceAvailabilityContext()
  const [closedEvents, setClosedEvents] = useState([])
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)
  const servicesLookup = useSelector(state => state.User.servicesLookup)

  const startOfDay = moment(props.date).startOf('day')
  const endOfDay = moment(props.date).endOf('day')
  const currentDayOfWeek = startOfDay.day()

  const columns = buildingIds.map(id => buildingsLookup[id]?.displayName)

  useEffect(() => {
    // TODO: This assumes every building has the same availability for a given day

    // the closed events are the times that each building is closed on a given day outside the period defined by calendarBounds
    setClosedEvents(
      columns.reduce((acc, columnName) => {
        const todayGa = generalCalendarAvailability.byDayOfWeekStartAndEnd[currentDayOfWeek]

        const closedEventDefaults = {
          color: Colors.rxrMediumLightGreyColor,
          content: 'Closed',
          column: columnName,
          isDisabled: true,
        }

        if (!todayGa || todayGa.start > todayGa.end) {
          // this happens when there are no general availability settings for this day -- therefore the closure should be the entire day
          acc.push({
            ...closedEventDefaults,
            id: `closed-complete-${uuid()}`,
            start: generalCalendarAvailability.start,
            end: generalCalendarAvailability.end,
          })
        } else {
          if (todayGa.start > generalCalendarAvailability.start) {
            acc.push({
              ...closedEventDefaults,
              id: `closed-start-${uuid()}`,
              start: todayGa.start,
              end: generalCalendarAvailability.start,
            })
          }
          if (todayGa.end < generalCalendarAvailability.end) {
            acc.push({
              ...closedEventDefaults,
              id: `closed-end-${uuid()}`,
              start: todayGa.end,
              end: generalCalendarAvailability.end,
            })
          }
        }

        return acc
      }, []),
    )
  }, [generalCalendarAvailability, currentDayOfWeek])

  /**
   * @param {{startAt: Date|string, endAt: Date|string}} r
   * @param {{buildingId: string}?} rawObj
   * @return {{column: string, start: number, end: number}}
   */
  function getColumnStartAndEndTimes(r, rawObj) {
    const startOfDay = moment(r.startAt).startOf('day')
    const retVal = {
      start: moment(r.startAt).diff(startOfDay, 'minutes'),
      end: moment(r.endAt).diff(startOfDay, 'minutes'),
    }

    if (rawObj && rawObj.buildingId) {
      retVal.column = buildingsLookup[rawObj.buildingId].displayName
    }
    return retVal
  }

  const getAppointmentContent = appointment => {
    const service = servicesLookup[appointment.vendorServiceId]
    return (
      <div className={classes.eventWrapper}>
        <strong>{service.label}</strong>
        <p>{appointment.resident.unitNumber}</p>
        <p>{appointment.resident.displayName}</p>
      </div>
    )
  }

  const getClosureContent = closure => {
    return (
      <div className={classes.eventWrapper}>
        <strong>{closure.label}</strong>
      </div>
    )
  }

  const {
    closureEvents,
    events,
    editingEvent,
    editingClosure,

    handleUpdateClosure,
    handleUpdateAppointment,
    resetAppointmentEvents,
    resetClosureEvents,
  } = useSharedCalendarLogic({
    getColumnStartAndEndTimes: getColumnStartAndEndTimes,
    columns: columns,
    getAppointmentContent: getAppointmentContent,
    getClosureContent: getClosureContent,
  })

  /**
   * @param {CalendarEventConfig} ev
   */
  function eventToClosure(ev) {
    const startAt = startOfDay.add(ev.start, 'minutes').toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    return {
      startAt: startAt,
      endAt: endAt,
      buildingIds: Object.values(buildingsLookup)
        .filter(b => b.displayName === ev.column)
        .map(b => b.id),
    }
  }

  const filteredClosureEvents = closureEvents.filter(
    cEv =>
      (moment(cEv.startAt).isBetween(startOfDay, endOfDay) || moment(cEv.endAt).isBetween(startOfDay, endOfDay)) &&
      buildingIds.some(b => closuresLookup[cEv.id] && closuresLookup[cEv.id].buildingIds.includes(b)),
  )

  return (
    <CalendarWithTooltip
      eventToObject={eventToClosure}
      columns={columns}
      events={events.concat(closedEvents).concat(filteredClosureEvents)}
      calendarStartTime={generalCalendarAvailability.start}
      calendarEndTime={generalCalendarAvailability.end}
      newAppointmentText={'New time block off'}
      disableEventDragging={true}
      renderEventMenu={(obj, id, onUpdateEvent, onCloseEvent) => (
        <EventMenuRenderer
          obj={obj}
          id={id}
          onUpdateEvent={onUpdateEvent}
          onCloseEvent={onCloseEvent}
          editingEvent={editingEvent}
          editingClosure={editingClosure}
          handleUpdateClosure={handleUpdateClosure}
          handleUpdateAppointment={handleUpdateAppointment}
          resetAppointmentEvents={resetAppointmentEvents}
          resetClosureEvents={resetClosureEvents}
          getColumnStartAndEndTimes={getColumnStartAndEndTimes}
        />
      )}
    />
  )
}

export default DayCalendar
