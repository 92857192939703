import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import TextInput from '../TextInput'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import MultilineTextInput from '../MultilineTextInput'
import useFormChanged, {
  DateObjectValidator,
  NonEmptyArrayValidator,
  NonEmptyStringValidator,
  ConstructGreaterThanOtherDateValidator,
} from '../../hooks/useFormChanged'
import RXRButton from '../RXRButton'
import BuildingSelector from '../BuildingSelector'
import DateTimeInput from '../DateTimeInput'
import {DUMMY_EVENT_ID} from '../Calendar/CalendarWithTooltip'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import useServiceAvailabilityContext from '../../hooks/useServiceAvailabilityContext'

const FormValidation = useFormChanged.PropLevelValidation({
  buildingIds: NonEmptyArrayValidator,
  startAt: DateObjectValidator,
  endAt: ConstructGreaterThanOtherDateValidator('startAt'),
  notes: NonEmptyStringValidator,
})

const EMPTY_FORM = {
  id: null,
  buildingIds: [],
  startAt: null,
  endAt: null,
  notes: '',
}

function TimeBlockOffForm(props) {
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)
  const {validateForm, invalidItems, changedItems, resetInitialForm, form, setForm} = useFormChanged(EMPTY_FORM, FormValidation)
  const [isSaving, setIsSaving] = useState(false)
  const [confirmDelete, setConfrimDelete] = useState(false)
  const {createOrUpdateClosure} = useServiceAvailabilityContext()

  useEffect(() => {
    if (props.closureData) {
      resetInitialForm({
        id: props.closureData.id,
        notes: props.closureData.label,
        startAt: new Date(props.closureData.startAt),
        endAt: new Date(props.closureData.endAt),
        buildingIds: props.closureData.buildingIds || [],
      })
    }
  }, [props.closureData])

  const handleUpdateForm = changes => {
    setForm(changes)
    props.onChange({...form, ...changes})
  }

  const saveChanges = async () => {
    if (!validateForm()) {
      return
    }
    setIsSaving(true)

    try {
      for (const buildingId of form.buildingIds) {
        await createOrUpdateClosure({...form, buildingId: buildingId})
      }
    } catch (err) {
      window.alert(err.message)
    }
    setIsSaving(false)
    props.onSaveComplete()
  }

  const deleteBlockOff = async () => {
    setConfrimDelete(false)
    setIsSaving(true)
    try {
      for (const buildingId of form.buildingIds) {
        await createOrUpdateClosure({...form, buildingId: buildingId}, true)
      }
    } catch (err) {
      window.alert(err.message)
    }
    props.onSaveComplete()
    setIsSaving(false)
  }

  const isCreating = form.id === DUMMY_EVENT_ID

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <span>Time Block Off</span>
      </Grid>
      <Grid item sm={12}>
        <BuildingSelector selected={form.buildingIds} onChange={buildingIds => handleUpdateForm({buildingIds})}>
          <TextInput
            label="Building(s)"
            value={form.buildingIds.map(bId => buildingsLookup[bId]?.displayName).join(', ')}
            isDisabled={!isCreating}
            isRequired
            error={invalidItems.includes('buildingIds')}
          />
        </BuildingSelector>
      </Grid>
      <Grid item sm={12}>
        <DateTimeInput
          label={'Start date and start time'}
          onChange={startDate => handleUpdateForm({startAt: startDate})}
          min={new Date()}
          value={form.startAt}
          isRequired
          error={invalidItems.includes('startAt')}
        />
      </Grid>
      <Grid item sm={12}>
        <DateTimeInput
          label={'End date and end time'}
          onChange={endDate => handleUpdateForm({endAt: endDate})}
          value={form.endAt}
          disabled={!form.startAt}
          min={form.startAt}
          isRequired
          error={invalidItems.includes('endAt')}
        />
      </Grid>
      <Grid item sm={12}>
        <MultilineTextInput
          label={'Notes'}
          value={form.notes}
          onChange={notes => handleUpdateForm({notes})}
          isRequired
          error={invalidItems.includes('notes')}
        />
      </Grid>
      <Grid item xs={12}>
        <RXRButton isLoading={isSaving} onClick={saveChanges}>
          {isCreating ? 'Disable Booking for this Time' : 'Update Time Block Off'}
        </RXRButton>
      </Grid>
      {!isCreating && (
        <Grid item xs={12}>
          <RXRButton type={RXRButton.TYPE_TEXT} isLoading={isSaving} onClick={() => setConfrimDelete(true)}>
            Delete time block off
          </RXRButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <RXRButton type={RXRButton.TYPE_TEXT} isLoading={isSaving} onClick={props.onCancel}>
          Close
        </RXRButton>
      </Grid>

      <ConfirmDeleteDialog
        isOpen={confirmDelete}
        onCancel={() => setConfrimDelete(false)}
        onConfirmDelete={deleteBlockOff}
        prompt="Are you sure you want to remove this time block off?"
        cancelButtonText="No, return"
        deleteButtonText="Yes, delete"
      />
    </Grid>
  )
}

TimeBlockOffForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSaveComplete: PropTypes.func.isRequired,
  closureData: PropTypes.object,
}

export default TimeBlockOffForm
