

export default {
  getBuilding:{"id":"ID!"},
  listBuildings:{"filter":"ModelBuildingFilterInput","limit":"Int","nextToken":"String"},
  createBuilding:{"input":"CreateBuildingInput!"},
  updateBuilding:{"input":"UpdateBuildingInput!"},
  deleteBuilding:{"input":"DeleteBuildingInput!"},
  getBuildingStaff:{"id":"ID!"},
  listBuildingStaffs:{"filter":"ModelBuildingStaffFilterInput","limit":"Int","nextToken":"String"},
  listBuildingStaffByBuilding:{"buildingId":"ID","staffId":"ModelIDKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelBuildingStaffFilterInput","limit":"Int","nextToken":"String"},
  listBuildingStaffByStaff:{"staffId":"ID","buildingId":"ModelIDKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelBuildingStaffFilterInput","limit":"Int","nextToken":"String"},
  createBuildingStaff:{"input":"CreateBuildingStaffInput!"},
  updateBuildingStaff:{"input":"UpdateBuildingStaffInput!"},
  deleteBuildingStaff:{"input":"DeleteBuildingStaffInput!"},
  getOccupancy:{"id":"ID!"},
  listOccupancys:{"filter":"ModelOccupancyFilterInput","limit":"Int","nextToken":"String"},
  listOccupancysByYardiId:{"yardiId":"String","status":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelOccupancyFilterInput","limit":"Int","nextToken":"String"},
  createOccupancy:{"input":"CreateOccupancyInput!"},
  updateOccupancy:{"input":"UpdateOccupancyInput!"},
  deleteOccupancy:{"input":"DeleteOccupancyInput!"},
  getRecurringVendorAppointment:{"id":"ID!"},
  listRecurringVendorAppointments:{"filter":"ModelRecurringVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listRecurringVendorAppointmentsByResident:{"residentId":"ID","lastOccurredAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelRecurringVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listRecurringVendorAppointmentsByBuilding:{"buildingId":"ID","lastOccurredAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelRecurringVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listRecurringVendorAppointmentsByVendorService:{"vendorServiceId":"ID","lastOccurredAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelRecurringVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  createRecurringVendorAppointment:{"input":"CreateRecurringVendorAppointmentInput!"},
  updateRecurringVendorAppointment:{"input":"UpdateRecurringVendorAppointmentInput!"},
  deleteRecurringVendorAppointment:{"input":"DeleteRecurringVendorAppointmentInput!"},
  getResident:{"id":"ID!"},
  listResidents:{"filter":"ModelResidentFilterInput","limit":"Int","nextToken":"String"},
  listResidentsByReferenceId:{"secondaryReferenceId":"String","referenceId":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelResidentFilterInput","limit":"Int","nextToken":"String"},
  sendNotification:{"residentId":"ID!","subject":"String","body":"String","badge":"Int","appJSON":"String"},
  createResidentUser:{"input":"CreateResidentUserInput!"},
  createResident:{"input":"CreateResidentInput!"},
  updateResident:{"input":"UpdateResidentInput!"},
  deleteResident:{"input":"DeleteResidentInput!"},
  listStaffAtBuilding:{"buildingId":"ID!"},
  getStaff:{"id":"ID!"},
  listStaffs:{"filter":"ModelStaffFilterInput","limit":"Int","nextToken":"String"},
  createGroupUser:{"input":"CreateGroupUserInput!"},
  updateGroupUser:{"input":"UpdateGroupUserInput!"},
  disableGroupUser:{"input":"DisableGroupUserInput!"},
  resetGroupUserPassword:{"input":"ResetGroupUserPasswordInput!"},
  createStaff:{"input":"CreateStaffInput!"},
  updateStaff:{"input":"UpdateStaffInput!"},
  deleteStaff:{"input":"DeleteStaffInput!"},
  getUnit:{"id":"ID!"},
  listUnits:{"filter":"ModelUnitFilterInput","limit":"Int","nextToken":"String"},
  unitsByBuildingAndNumber:{"unitBuildingId":"ID","number":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelUnitFilterInput","limit":"Int","nextToken":"String"},
  createUnit:{"input":"CreateUnitInput!"},
  updateUnit:{"input":"UpdateUnitInput!"},
  deleteUnit:{"input":"DeleteUnitInput!"},
  getVendorAppointment:{"id":"ID!"},
  listVendorAppointments:{"filter":"ModelVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listVendorAppointmentsByForeignAppointment:{"foreignAppointmentId":"ID","startAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listVendorAppointmentsByStatus:{"status":"ReservationState","startAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listVendorAppointmentsByResident:{"residentId":"ID","startAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listVendorAppointmentsByBuilding:{"buildingId":"ID","startAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  listVendorAppointmentsByVendorService:{"vendorServiceId":"ID","startAt":"ModelStringKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorAppointmentFilterInput","limit":"Int","nextToken":"String"},
  createNewVendorAppointment:{"input":"CreateNewVendorAppointmentInput!"},
  rescheduleExistingVendorAppointment:{"input":"RescheduleVendorAppointmentInput!"},
  cancelExistingVendorAppointment:{"input":"CancelVendorAppointmentInput!"},
  createVendorAppointment:{"input":"CreateVendorAppointmentInput!"},
  updateVendorAppointment:{"input":"UpdateVendorAppointmentInput!"},
  deleteVendorAppointment:{"input":"DeleteVendorAppointmentInput!"},
  getVendorService:{"id":"ID!"},
  listVendorServices:{"filter":"ModelVendorServiceFilterInput","limit":"Int","nextToken":"String"},
  listVendorServicesByForeignService:{"foreignServiceId":"ID","buildingId":"ModelIDKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorServiceFilterInput","limit":"Int","nextToken":"String"},
  listVendorServicesByBuilding:{"buildingId":"ID","typeSubType":"ModelVendorServiceByBuildingCompositeKeyConditionInput","sortDirection":"ModelSortDirection","filter":"ModelVendorServiceFilterInput","limit":"Int","nextToken":"String"},
  createVendorService:{"input":"CreateVendorServiceInput!"},
  updateVendorService:{"input":"UpdateVendorServiceInput!"},
  deleteVendorService:{"input":"DeleteVendorServiceInput!"}
}
