import GraphQLHelper from '../GQLQueryHelper'
import customHooks from '../customHooks'
import Building from './Building'
import BuildingStaff from './BuildingStaff'
import Occupancy from './Occupancy'
import RecurringVendorAppointment from './RecurringVendorAppointment'
import Resident from './Resident'
import Staff from './Staff'
import Unit from './Unit'
import VendorAppointment from './VendorAppointment'
import VendorService from './VendorService'

const allCollections = {
  Building,
  BuildingStaff,
  Occupancy,
  RecurringVendorAppointment,
  Resident,
  Staff,
  Unit,
  VendorAppointment,
  VendorService,
}

const modelNameToCollectionMap = {}
Object.values(allCollections).forEach(c => {
  modelNameToCollectionMap[c.modelName] = c

  // every collection will keep a reference to this lookup to assist with calling query hooks on associated models
  c.modelNameToCollectionMap = modelNameToCollectionMap
})

/**
 * @param {*} config
 */
function init(config) {
  // initialize our query helper
  GraphQLHelper.Instance(config)

  // set up our custom query hooks
  if (customHooks['Building']) { Object.keys(customHooks['Building']).forEach(h => {Building['custom_'+h] = customHooks['Building'][h]}) } 
  if (customHooks['BuildingStaff']) { Object.keys(customHooks['BuildingStaff']).forEach(h => {BuildingStaff['custom_'+h] = customHooks['BuildingStaff'][h]}) } 
  if (customHooks['Occupancy']) { Object.keys(customHooks['Occupancy']).forEach(h => {Occupancy['custom_'+h] = customHooks['Occupancy'][h]}) } 
  if (customHooks['RecurringVendorAppointment']) { Object.keys(customHooks['RecurringVendorAppointment']).forEach(h => {RecurringVendorAppointment['custom_'+h] = customHooks['RecurringVendorAppointment'][h]}) } 
  if (customHooks['Resident']) { Object.keys(customHooks['Resident']).forEach(h => {Resident['custom_'+h] = customHooks['Resident'][h]}) } 
  if (customHooks['Staff']) { Object.keys(customHooks['Staff']).forEach(h => {Staff['custom_'+h] = customHooks['Staff'][h]}) } 
  if (customHooks['Unit']) { Object.keys(customHooks['Unit']).forEach(h => {Unit['custom_'+h] = customHooks['Unit'][h]}) } 
  if (customHooks['VendorAppointment']) { Object.keys(customHooks['VendorAppointment']).forEach(h => {VendorAppointment['custom_'+h] = customHooks['VendorAppointment'][h]}) } 
  if (customHooks['VendorService']) { Object.keys(customHooks['VendorService']).forEach(h => {VendorService['custom_'+h] = customHooks['VendorService'][h]}) } 
}


export default {
  ...allCollections,
  modelNameToCollectionMap,
  init
}
