import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Spacing, Typography as TypographyStyle, Colors} from '../assets/styles'
import {constructClassString} from '../Utils/objectUtil'
import {useSelector} from 'react-redux'

const PageHeader = forwardRef(function PageHeader(props, ref) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles()

  return (
    <div
      className={constructClassString(classes.appBar, props.className)}
      style={isMobile ? {minHeight: PageHeader.MIN_HEIGHT_MOBILE} : undefined}
      ref={ref}
    >
      <Grid container className={classes.headerContainer} style={isMobile ? {padding: Spacing.spaceSmall} : undefined}>
        <Grid item xs={props.rightControlComponent ? 6 : 12}>
          {!isMobile && (
            <Typography noWrap className={classes.headerOne} style={isMobile ? {fontSize: TypographyStyle.fontSizeLarge} : undefined}>
              {props.title}
            </Typography>
          )}
        </Grid>
        {props.rightControlComponent && (
          <Grid item xs={6} className={classes.rightComponent}>
            {props.rightControlComponent}
          </Grid>
        )}
      </Grid>
      {props.children && (
        <div
          className={classes.childrenContainer}
          style={isMobile ? {paddingLeft: Spacing.spaceSmall, paddingRight: Spacing.spaceSmall} : undefined}
        >
          {props.children}
        </div>
      )}
    </div>
  )
})

PageHeader.MIN_HEIGHT_MOBILE = 40
PageHeader.MIN_HEIGHT = 116

const useStyles = makeStyles(theme => ({
  appBar: {
    minHeight: PageHeader.MIN_HEIGHT,
    flexShrink: 0,
    position: 'relative',
    // boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: Colors.rxrWhiteColor,
    borderBottom: '1px solid ' + Colors.rxrGreyColor,
  },
  headerContainer: {
    padding: Spacing.spaceMedium,
  },
  childrenContainer: {
    paddingLeft: Spacing.spaceMedium,
    paddingRight: Spacing.spaceMedium,
  },
  headerOne: TypographyStyle.AppBarHeader,
  rightComponent: {
    textAlign: 'right',
  },
}))

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  rightControlComponent: PropTypes.any,
  hideDivider: PropTypes.bool,
  className: PropTypes.string,
}

export default PageHeader
