import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class BuildingStaff extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getBuildingStaff(input, options) {
    return this._get(
       'getBuildingStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getBuildingStaff(input, options) {
    return new this().getBuildingStaff(input, options)
  }

  /**
   * @param { {filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listBuildingStaffs(input, options) {
    return this._list(
       'listBuildingStaffs',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listBuildingStaffs(input, options) {
    return new this().listBuildingStaffs(input, options)
  }

  /**
   * @param { {filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateBuildingStaffs(input, options) {
    return this._iterate(
       'listBuildingStaffs',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateBuildingStaffs(input, options) {
    return new this().iterateBuildingStaffs(input, options)
  }

  /**
   * @param { {buildingId?: ID, staffId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listBuildingStaffByBuilding(input, options) {
    return this._list(
       'listBuildingStaffByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, staffId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listBuildingStaffByBuilding(input, options) {
    return new this().listBuildingStaffByBuilding(input, options)
  }

  /**
   * @param { {buildingId?: ID, staffId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateBuildingStaffByBuilding(input, options) {
    return this._iterate(
       'listBuildingStaffByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, staffId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateBuildingStaffByBuilding(input, options) {
    return new this().iterateBuildingStaffByBuilding(input, options)
  }

  /**
   * @param { {staffId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listBuildingStaffByStaff(input, options) {
    return this._list(
       'listBuildingStaffByStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {staffId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listBuildingStaffByStaff(input, options) {
    return new this().listBuildingStaffByStaff(input, options)
  }

  /**
   * @param { {staffId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateBuildingStaffByStaff(input, options) {
    return this._iterate(
       'listBuildingStaffByStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {staffId?: ID, buildingId?: ModelIDKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelBuildingStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateBuildingStaffByStaff(input, options) {
    return new this().iterateBuildingStaffByStaff(input, options)
  }

  /**
   * @param { {input: CreateBuildingStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createBuildingStaff(input, options) {
    return this._mutate(
       'createBuildingStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateBuildingStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createBuildingStaff(input, options) {
    return new this().createBuildingStaff(input, options)
  }

  /**
   * @param { {input: UpdateBuildingStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateBuildingStaff(input, options) {
    return this._mutate(
       'updateBuildingStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateBuildingStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateBuildingStaff(input, options) {
    return new this().updateBuildingStaff(input, options)
  }

  /**
   * @param { {input: DeleteBuildingStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteBuildingStaff(input, options) {
    return this._mutate(
       'deleteBuildingStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteBuildingStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteBuildingStaff(input, options) {
    return new this().deleteBuildingStaff(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "BuildingStaff"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    Object.entries(BuildingStaff._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = BuildingStaff.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })


    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof BuildingStaff.custom_afterFind === 'function') {
      return BuildingStaff.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { BuildingStaff }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
BuildingStaff._complexFields = {"staff":"Staff!","building":"Building!"}
BuildingStaff._jsonFields = []

BuildingStaff.FragmentDefault = `
fragment BuildingStaffFragmentDefault on BuildingStaff {
  id
  buildingId
  staffId
  isDisabled
  createdAt
  updatedAt
}

`

BuildingStaff.WithBuilding = `
fragment BuildingStaffWithBuilding on BuildingStaff {
  buildingId
  building {
    id
    displayName
  }
}

`


export default BuildingStaff
