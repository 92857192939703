import {makeStyles} from '@mui/styles'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import AppAuth from '../../lib/AppAuth'
import PhoneNumberInput from './PhoneNumberInput'
import Verify from './Verify'
import useNavigate from '../../hooks/useNavigateShim'
import RouteConstants from '../../constants/routes'
import CenteredSpinner from '../CenteredSpinner'
import AuthPage from './AuthPage'
import {heading, inputRow} from './authStyles'
import {setUserAction} from '../../actions/userActions'
import {spaceMedium, spaceLarge} from '../../../../src/assets/styles/spacing'
import {fontSizeMedium} from '../../../../src/assets/styles/typography'
import {rxrRedColor} from '../../assets/styles/color'

const DEFAULT_EMAIL = 'support@rxohome.zendesk.com'
const defaultErrMessage = `We're having trouble logging you in. Please contact ${DEFAULT_EMAIL} for assistance.`

const errCodesToMessages = {
  CodeMismatchException: 'Sorry, the code you entered was incorrect.',
  ExpiredCodeException: 'That code has expired, request a new one. ',
  LoginError: 'Sorry, the code you entered was incorrect.',
  VendorError: `Sorry, only current vendors have access to the RXO Home app. Please contact ${DEFAULT_EMAIL} if you believe this is an error.`,
  ConfigurationError: `Sorry, we’ve had some issues setting up your account. Please try logging in again. If you continue to experience issues, contact ${DEFAULT_EMAIL}`,
  NotAuthorizedException: `You must be a resident to access the app. Please email ${DEFAULT_EMAIL} for assistance.`,
  LimitExceededException: 'You have exceeded the number of login attempts, please wait some time and try again. ',
}

const useStyles = makeStyles(theme => ({
  heading: heading,
  inputRow: inputRow,
  forgotPasswordBtn: {
    fontSize: '0.75rem',
  },
  phoneNumberInputContainer: {
    marginLeft: spaceMedium,
    marginRight: spaceMedium,
  },
  loginError: {
    fontSize: fontSizeMedium,
    textAlign: 'center',
    margin: `${spaceLarge}px 0`,
    color: rxrRedColor,
  },
}))

function Login() {
  const classes = useStyles()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loginError, setLoginError] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isResettingPassword, setIsResettingPassword] = useState(false)
  const [temporaryPassword, setTemporaryPassword] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setTemporaryPassword(Math.random().toString(36).substr(2, 8))
  }, [])

  const handlePhoneNumberSubmit = async phoneNumber => {
    setIsLoggingIn(true)
    setPhoneNumber(phoneNumber)

    AppAuth.Instance()
      .signUp(phoneNumber, temporaryPassword)
      .then(() => setIsVerifying(true))
      .catch(err => {
        if (err.code === 'UsernameExistsException') {
          resetPassword(phoneNumber)
        } else {
          setLoginError(defaultErrMessage)
        }
      })
      .finally(() => setIsLoggingIn(false))
  }

  const resetPassword = loginNum => {
    setIsResettingPassword(true)
    setIsVerifying(true)
    AppAuth.Instance()
      .forgotPassword(loginNum)
      .catch(err => {
        if (err.code === 'InvalidParameterException') {
          // They already tried creating an account, but never verified their email, so a user exists but is unverified
          setIsResettingPassword(false)
          return AppAuth.Instance().resendSignUp(loginNum)
        }
        setLoginError(errCodesToMessages[err.code] || defaultErrMessage)
        setIsVerifying(false)
      })
  }

  const handleOTPCodeSubmit = async code => {
    setIsLoggingIn(true)
    // Confirm signup (or confirm resend/re-enter code with incorrect input)
    if (isResettingPassword === false) {
      try {
        await AppAuth.Instance().confirmSignUp(phoneNumber, code)
        await AppAuth.Instance().signIn(phoneNumber, temporaryPassword)
        return await handleVendorLogin()
      } catch (err) {
        handleErrors(err)
      }
    } else {
      // reset password
      try {
        await AppAuth.Instance().forgotPasswordSubmit(phoneNumber, code, temporaryPassword)
        await AppAuth.Instance().signIn(phoneNumber, temporaryPassword)
        await handleVendorLogin()
      } catch (err) {
        handleErrors(err)
      }
    }
  }

  const handleVendorLogin = async () => {
    const cognitoUser = await AppAuth.Instance().getAuthenticatedUser()

    if (!cognitoUser) {
      throw new AppAuth.LoginError('No user found')
    }

    const vendorId = cognitoUser.attributes.sub
    try {
      setUserAction(dispatch, vendorId)
      setIsLoggingIn(false)
      navigate(RouteConstants.constructPath(RouteConstants.ROOT))
    } catch (err) {
      setLoginError(errCodesToMessages.VendorError)
      setIsLoggingIn(false)
      setUserAction(dispatch, null)
      throw new AppAuth.VendorError(`No vendor found with id ${vendorId}`)
    }
  }

  const handleErrors = err => {
    // TBU: BETTER ERROR LOGGING

    setLoginError(errCodesToMessages[err.code] || defaultErrMessage)
    setIsLoggingIn(false)
  }

  return (
    <AuthPage isLoading={isLoggingIn}>
      <p className={classes.heading}>Sign in to Vendor Dashboard</p>
      {!isVerifying ? (
        <div className={classes.phoneNumberInputContainer}>
          <PhoneNumberInput
            hideDisclaimer={!!(isLoggingIn || loginError)}
            onSubmit={handlePhoneNumberSubmit}
            setError={msg => setLoginError(msg)}
          />
        </div>
      ) : (
        <Verify
          hideResendPrompt={isLoggingIn}
          phoneNumber={phoneNumber}
          onCodeSubmit={handleOTPCodeSubmit}
          resettingPassword={isResettingPassword}
          setError={msg => setLoginError(msg)}
        />
      )}
      {isLoggingIn ? (
        <CenteredSpinner className={classes.spinner} />
      ) : loginError ? (
        <p className={classes.loginError}>{loginError}</p>
      ) : null}
    </AuthPage>
  )
}

export default Login
