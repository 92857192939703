import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {Colors, Spacing, Typography} from '../../assets/styles'

const NumberBox = forwardRef(function NumberBox(props, ref) {
  const classes = useStyles()

  return (
    <input
      autoFocus={props.autoFocus}
      type="tel"
      value={props.value}
      ref={ref}
      className={classes.smallInput}
      maxLength={1}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
    />
  )
})

const useStyles = makeStyles(theme => ({
  smallInput: {
    width: '50px',
    height: '70px',
    borderRadius: '5px',
    border: `1px solid ${Colors.rxrDarkGreyColor}`,
    textAlign: 'center',
    marginRight: Spacing.spaceExtraSmall,
    fontFamily: 'proxima_nova_regular',
    fontSize: Typography.fontSizeExtraLarge,

    [theme.breakpoints.down('md')]: {
      width: '40px',
      height: '55px',
      marginRight: Spacing.spaceExtraExtraSmall,
      fontSize: Typography.fontSizeLarge,
    },

    [theme.breakpoints.down('sm')]: {
      width: '32px',
      height: '45px',
      fontSize: Typography.fontSizeMedium,
      marginRight: 1,
    },
  },
}))

NumberBox.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
}

export default NumberBox
