import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class Building extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getBuilding(input, options) {
    return this._get(
       'getBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getBuilding(input, options) {
    return new this().getBuilding(input, options)
  }

  /**
   * @param { {filter?: ModelBuildingFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listBuildings(input, options) {
    return this._list(
       'listBuildings',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelBuildingFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listBuildings(input, options) {
    return new this().listBuildings(input, options)
  }

  /**
   * @param { {filter?: ModelBuildingFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateBuildings(input, options) {
    return this._iterate(
       'listBuildings',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelBuildingFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateBuildings(input, options) {
    return new this().iterateBuildings(input, options)
  }

  /**
   * @param { {input: CreateBuildingInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createBuilding(input, options) {
    return this._mutate(
       'createBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateBuildingInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createBuilding(input, options) {
    return new this().createBuilding(input, options)
  }

  /**
   * @param { {input: UpdateBuildingInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateBuilding(input, options) {
    return this._mutate(
       'updateBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateBuildingInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateBuilding(input, options) {
    return new this().updateBuilding(input, options)
  }

  /**
   * @param { {input: DeleteBuildingInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteBuilding(input, options) {
    return this._mutate(
       'deleteBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteBuildingInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteBuilding(input, options) {
    return new this().deleteBuilding(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "Building"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    Object.entries(Building._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = Building.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })

    // automatically JSON Parse all AWSJSON fields
    Building._jsonFields.forEach(field => {
      if (Object.hasOwnProperty.call(obj, field) && typeof obj[field] === 'string') {
        obj[field] = JSON.parse(obj[field])
      }
    })

    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof Building.custom_afterFind === 'function') {
      return Building.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { Building }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
Building._complexFields = {"units":"[Unit]","staff":"[BuildingStaff]","events":"[SocialEvent]","cleaningPrices":"[CleaningPrice]","announcements":"[Announcement]","conversations":"[Conversation]","partners":"[BuildingPartner]","amenities":"[Amenity]","disabledFeatures":"[CoreFeature]"}
Building._jsonFields = ["officeHours","socialLinks","onboardingSchema",]

Building.FragmentDefault = `
fragment BuildingFragmentDefault on Building {
  id
  displayName
  yardiId
  oscarId
  email
  streetAddress
  city
  state
  zipCode
  website
  phoneNumber
  officeHours
  socialLinks
  emergencyMaintenancePhoneNumber
  taxRate
  outOfOfficeStatus
  freeCleaningOffering
  launchDate
  supportEmail
  lastSyncedYardi
  lastSyncedOscar
  leaseRenewalURL
  supportFormURL
  timezone
  outOfOfficeMessage
  onboardingSchema
  smartHubLaunchDate
  createdAt
  updatedAt
}

`


export default Building
