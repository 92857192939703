export default {
  ROOT: window.location.pathname,

  DASHBOARD_ROOT: '/',
  LOGIN: '/login',
  BOOKINGS: '/bookings',
  CALENDAR: '/calendar',
  SERVICE_MANAGER: '/service-manager',

  /**
   * Example usage:
   * route: '/foo/:bar/bizz/:bop'
   * params: {bar: 5, bop: 10}
   * output: '/foo/5/bizz/10'
   *
   * @param {string} route
   * @param {*} params
   * @returns {*}
   */
  constructPath: (route, params) => {
    let retVal = route
    if (typeof params === 'object') {
      Object.entries(params).forEach(tuple => {
        const paramName = ':' + tuple[0]

        // swap the param name for the discrete value
        retVal = retVal.replace(paramName, tuple[1])
      })
    }

    return retVal
  },
}
