import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {makeStyles} from '@mui/styles'
import {Spacing, Colors} from '../assets/styles'
import GenericFilterMenu from './GenericFilterMenu'

function BuildingSelector(props) {
  const classes = useStyles()
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)
  const buildingIds = useSelector(state => state.User.buildingIds)

  const buildingMap = buildingIds.reduce((acc, bId) => {
    acc[bId] = buildingsLookup[bId].displayName
    return acc
  }, {})

  return (
    <div className={classes.container}>
      <GenericFilterMenu onChange={props.onChange} selectedFilters={props.selected} filterLabelMap={buildingMap} minimumSelected={1}>
        {props.children}
      </GenericFilterMenu>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {},
  input: {},
}))

BuildingSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default BuildingSelector
