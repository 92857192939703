import {combineReducers} from 'redux'
import appReducers from './appReducers'
import appointmentsReducer from './appointmentsReducer'
import userReducer from './userReducer'
import ActionTypes from '../actions/types'

import {CACHE_NanPanelOpenStatus} from '../constants/AppConstants'

const appReducer = combineReducers({
  App: appReducers,
  Appointments: appointmentsReducer,
  User: userReducer,
})

export default (state, action) => {
  if (action.type === ActionTypes._RESET_APP_STATE) {
    state = action.payload

    delete localStorage[CACHE_NanPanelOpenStatus]
  }
  return appReducer(state, action)
}
