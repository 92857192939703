import ActionTypes from '../actions/types'
import {LOADING_STATE_NOT_LOADED} from '../constants/AppConstants'

/**
 * @typedef {Object} ResidentData
 * @property {string} id
 * @property {string} displayName
 * @property {string} phoneNumber
 * @property {string?} email
 * @property {string} unitNumber
 */

/**
 * @typedef {Object} AppointmentData
 * @property {string} id
 * @property {string} buildingId
 * @property {string} status
 * @property {string} startAt
 * @property {string} endAt
 * @property {ResidentData} resident
 */

/**
 * @typedef {Object} AppointmentsState
 * @property {Object<string, AppointmentData>} appointmentsLookup
 * @property {Array<string>} appointmentIds
 * @property {LoadingStateMeta} loading
 */

/** @type {AppointmentsState} */
const initialState = {
  appointmentsLookup: {},
  appointmentIds: [],
  loading: {
    status: LOADING_STATE_NOT_LOADED,
    meta: null,
  },
}

// --------------------------------------------------------------------------------
// State mutation functions:

/**
 * @param {AppointmentsState} state
 * @param {Array<AppointmentData>} appointments
 * @returns {AppointmentsState}
 */
function loadAppointments(state, appointments) {
  const appointmentsLookup = {}
  const appointmentIds = []

  appointments.forEach(appointment => {
    appointmentsLookup[appointment.id] = appointment
    appointmentIds.push(appointment.id)
  })

  return {
    ...state,
    appointmentsLookup,
    appointmentIds,
  }
}

/**
 * @param {AppointmentsState} state
 * @param {AppointmentData} appointment
 * @returns {AppointmentsState}
 */
function updateAppointment(state, appointment) {
  return {
    ...state,
    appointmentsLookup: {
      ...state.appointmentsLookup,
      [appointment.id]: appointment,
    },
  }
}

// --------------------------------------------------------------------------------

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.VENDOR_APPOINTMENTS_LOADED:
      return loadAppointments(state, action.payload)

    case ActionTypes.VENDOR_APPOINTMENTS_LOADING_STATUS:
      return {...state, loading: action.payload}

    case ActionTypes.VENDOR_APPOINTMENT_UPDATE:
      return updateAppointment(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
