import React, {useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Pollable from '../lib/Pollable'
import {loadVendorAppointments} from '../actions/appointmentActions'

function BackgroundDataSync() {
  const isInitialized = useSelector(state => state.User.isInitialized)
  const buildingIds = useSelector(state => state.User.buildingIds)
  const dispatch = useDispatch()
  const pollables = useRef([])

  useEffect(() => {
    if (!isInitialized) {
      pollables.current.forEach(pollable => pollable.stopPolling())
    } else {
      pollables.current = [
        // Load appointments every 10 minutes
        new Pollable('LoadAppointments', 1000 * 60 * 10, () => loadVendorAppointments(dispatch, buildingIds)),
      ]

      pollables.current.forEach(pollable => pollable.startPolling())
    }
  }, [isInitialized, buildingIds])

  return null
}

export default BackgroundDataSync
