/**
 * EXAMPLE USE CASE:
 * obj= {foo:{bar: 5}}
 * property= 'foo.bar'
 * returns 5
 *
 * @param {object} obj -- a potentially deeply nested object
 * @param {string} property -- a dot separated sequence of properties to follow
 * @param {boolean?} doNotThrow -- if true, will return null instead of throwing on invalid property path
 * @returns {*}
 */
export function followDotNotation(obj, property, doNotThrow) {
  const parts = property.split('.')

  let retVal = obj
  let i = 0
  do {
    if (typeof retVal === 'object' && !!retVal && Object.prototype.hasOwnProperty.call(retVal, parts[i])) {
      retVal = retVal[parts[i]]
      i++
    } else {
      if (doNotThrow) {
        return null
      }
      console.error('Invalid property path ' + property)
      console.log(obj)
      throw new Error('Invalid property path ' + property)
    }
  } while (i < parts.length)

  return retVal
}

/**
 * @param {*} map
 * @param {boolean?} sortByLabel
 * @returns {Array<{value: string, label: string}>}
 */
export function convertKeyLabelMapIntoSelectOptions(map, sortByLabel = false) {
  let retVal = Object.entries(map).map(tuple => ({value: tuple[0], label: tuple[1]}))

  if (sortByLabel) {
    retVal.sort((a, b) => {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    })
  }

  return retVal
}

/**
 * @param {string|Object<string, boolean>} classes
 * @returns {string}
 */
export function constructClassString(...classes) {
  return classes
    .filter(o => !!o)
    .reduce((acc, curr) => {
      if (typeof curr === 'string') {
        acc.push(curr)
      } else if (typeof curr === 'object') {
        acc.push(
          ...Object.entries(curr || {})
            .filter(([key, value]) => !!value)
            .map(([key, value]) => key),
        )
      }

      return acc
    }, [])
    .join(' ')
}
