import React from 'react'
import PropTypes from 'prop-types'
import {useLocation, matchPath} from 'react-router-dom'
import useNavigate from '../hooks/useNavigateShim'
import {IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useDispatch, useSelector} from 'react-redux'
import Routes from '../constants/routes'
import {Colors, Spacing, Typography as TypographyStyle} from '../assets/styles'
import RXRIcon from './RXRIcon'
import RXOHomeLogo from '../assets/images/RXOHomeIconGradient.png'
import {closeNavPanel, toggleNavPanelOpenStatus} from '../actions/appActions'
import {setUserAction} from '../actions/userActions'
import AppAuth from '../lib/AppAuth'
import pJson from '../../package.json'

const {rxrBlackDarkerColor, rxrBlueColor, rxrSoftHighlightedBlue, rxrTeal25Color, rxrTealColor, rxrWhiteColor} = Colors

const {spaceExtraSmall} = Spacing

const tailSize = 12
const iconSize = 20
const iconContainer = 48

const navItemBasics = {
  padding: 0,
  borderRadius: 6,
  color: rxrWhiteColor,
  cursor: 'pointer',

  marginTop: 6,
  '&:hover': {
    backgroundColor: rxrSoftHighlightedBlue,
  },
}

const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    width: spaceExtraSmall,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: rxrTeal25Color,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: rxrBlackDarkerColor,
    borderRadius: '4px',
  },
}

// magic number of the height of the nav bar when closed
export const MobileNavClosedHeight = 48

const useStyles = makeStyles(theme => ({
  navContainerMobile: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    backgroundColor: rxrBlueColor,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  navContainer: {
    position: 'relative',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: rxrBlueColor,
    padding: tailSize,
    overflow: 'hidden',
    transition: `width 0.2s`,
    flexShrink: 0,
  },
  logoContainer: {
    '& img': {
      width: iconContainer - 16,
      padding: `8px 3px`,
      backgroundColor: rxrWhiteColor,
      borderRadius: 5,
    },
  },
  menuItems: {
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: 'auto',
    ...scrollBarStyles,
  },
  navItemActive: {
    ...navItemBasics,
    backgroundColor: `${rxrTealColor} !important`,
  },
  navItem: {
    ...navItemBasics,
  },
  hamburgerMenu: navItemBasics,
  navItemIcon: {
    minWidth: 0,
    width: iconContainer,
    height: iconContainer,
    '& .MuiButtonBase-root': {
      width: '100%',
    },
  },
  navItemText: {
    marginLeft: 6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  versionNumber: {
    color: rxrWhiteColor,
    fontSize: TypographyStyle.fontSizeExtraSmall,
    position: 'absolute',
    bottom: 0,
    right: '4px',
    opacity: 0,
    transition: 'opacity 0.2s',

    '&:hover': {
      opacity: 1,
    },
  },
  bottomMenuItems: props => ({
    display: props.isMobileNavOpen ? 'block' : 'none',
    justifySelf: 'flex-end',
  }),
}))

function ResponsiveDrawer(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const isNavOpen = useSelector(state => state.App.isNavPanelOpen)
  const classes = useStyles({isMobile, isMobileNavOpen: isNavOpen})
  const currentLocation = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navPanelWidth = useSelector(state => state.App.navPanelWidth)

  const handleLogOut = async () => {
    await AppAuth.Instance().logOut()
    setUserAction(dispatch, null)
    navigate(Routes.ROOT)
  }

  const drawerNavigationData = [
    {
      name: 'Bookings',
      icon: RXRIcon.CLEANING,
      path: Routes.BOOKINGS,
      showInNav: true,
    },
    {
      name: 'Calendar',
      icon: RXRIcon.CALENDAR,
      path: Routes.CALENDAR,
      showInNav: true,
    },
  ]

  /**
   * @param {{path:string, childNav?:Array<*>}} navItem
   * @param {Node?} element
   */
  const handleClickNavItem = (navItem, element) => {
    // we're already on this path, return
    if (navItem.path === currentLocation.pathname) {
      return
    }

    navigate(navItem.path)
    if (isMobile) {
      closeNavPanel(dispatch)
    }
  }

  return (
    <div
      className={isMobile ? classes.navContainerMobile : classes.navContainer}
      style={isMobile ? {height: isNavOpen ? '100%' : MobileNavClosedHeight} : {width: navPanelWidth}}
    >
      <ListItem
        onClick={() => toggleNavPanelOpenStatus(dispatch, isNavOpen)}
        className={classes.hamburgerMenu}
        style={isMobile ? {marginTop: 0} : undefined}
      >
        <ListItemIcon className={classes.navItemIcon}>
          <IconButton color="primary" component="span" className={classes.logoContainer}>
            <img alt="RXO Home logo" src={RXOHomeLogo} />
          </IconButton>
        </ListItemIcon>
        {(isNavOpen || isMobile) && (
          <ListItemText className={classes.navItemText}>
            <Typography style={TypographyStyle.H4HeaderWithoutBold}>
              {isMobile
                ? // First we check to see if the currentLocation.pathname is a key in the drawerNavigationData object
                  drawerNavigationData.find(n => currentLocation.pathname.includes(n.path))?.name
                : 'RXO Home Vendors'}
            </Typography>
          </ListItemText>
        )}
      </ListItem>
      <List className={classes.menuItems} style={isMobile && !isNavOpen ? {display: 'none'} : undefined}>
        {drawerNavigationData
          .filter(navItem => navItem.showInNav)
          .map(navItem => {
            const isRouteActive = matchPath(currentLocation.pathname, {path: navItem.path})
            return (
              <ListItem
                key={navItem.path}
                onClick={ev => handleClickNavItem(navItem, ev.currentTarget)}
                className={isRouteActive ? classes.navItemActive : classes.navItem}
              >
                <ListItemIcon className={classes.navItemIcon}>
                  <IconButton color="primary" component="span">
                    <RXRIcon icon={navItem.icon} color={rxrWhiteColor} size={iconSize} />
                  </IconButton>
                </ListItemIcon>
                {isNavOpen && (
                  <ListItemText className={classes.navItemText}>
                    <Typography style={isRouteActive ? TypographyStyle.H4Header : TypographyStyle.H4HeaderWithoutBold}>
                      {navItem.name}
                    </Typography>
                  </ListItemText>
                )}
              </ListItem>
            )
          })}
      </List>
      <List className={classes.bottomMenuItems}>
        <ListItem onClick={handleLogOut} className={classes.navItem}>
          <ListItemIcon className={classes.navItemIcon}>
            <IconButton color="primary" component="span">
              <RXRIcon icon={RXRIcon.LOG_OUT} color={rxrWhiteColor} size={iconSize} />
            </IconButton>
          </ListItemIcon>
          {isNavOpen && (
            <ListItemText className={classes.navItemText}>
              <Typography style={TypographyStyle.H4HeaderWithoutBold}>{'Logout'}</Typography>
            </ListItemText>
          )}
        </ListItem>
      </List>
      <Typography className={classes.versionNumber}>{pJson.version}</Typography>
    </div>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
}
export default ResponsiveDrawer
