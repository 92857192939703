import {useContext} from 'react'
import {ServiceAvailabilityContext} from '../components/Calendar/ServiceAvailabilityProvider'

/**
 * @typedef {Object} ServiceAvailabilityContextData
 * @property {Array<string>} buildingIds
 * @property {Array<AppointmentData>} appointments
 * @property {boolean} isDataFetching
 * @property {Object<string, Array<ResourceClosure>>} closuresLookup
 * @property {{
 *    start: number,
 *    end: number,
 *    byDayOfWeekStartAndEnd:Array<{start: number, end: number}>,
 *  }} generalCalendarAvailability
 * @property {{current: Object<string, VendorServiceResourceSettings>}} buildingResourceSettingsCache
 * @property {function(ResourceClosure, boolean?): void} createOrUpdateClosure
 */

/**
 * @returns {ServiceAvailabilityContextData}
 */
function useServiceAvailabilityContext() {
  return useContext(ServiceAvailabilityContext)
}

export default useServiceAvailabilityContext
