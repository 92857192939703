export const TAB_UPCOMING = 0
export const TAB_PAST = 1
export const NO_UPCOMING_BOOKINGS = 'There are currently no upcoming bookings.'
export const NO_PAST_BOOKINGS = 'There are currently no past bookings.'

export const VENDOR_SERVICE_REQUESTED = 'REQUESTED'
export const VENDOR_SERVICE_CONFIRMED = 'CONFIRMED'
export const VENDOR_SERVICE_CONFIRMED_APPROVED = 'CONFIRMED_APPROVED'
export const VENDOR_SERVICE_CANCELLED = 'CANCELLED'
export const VENDOR_SERVICE_COMPLETED = 'COMPLETED'
export const VENDOR_SERVICE_UNDERWAY = 'UNDERWAY'
export const VENDOR_SERVICE_DECLINED = 'DECLINED'
export const VENDOR_SERVICE_COMPLETED_PAID = 'COMPLETED_PAID'
export const VENDOR_SERVICE_VENDOR_NO_SHOW = 'VENDOR_NO_SHOW'
export const VENDOR_SERVICE_NEEDS_RESCHEDULE = 'NEEDS_RESCHEDULE'
