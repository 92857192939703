import React, {useState} from 'react'
import {Layout, Spacing, Colors} from '../../assets/styles'
import PageHeader from '../PageHeader'
import {makeStyles} from '@mui/styles'
import WeekCalendar from './WeekCalendar'
import {useSelector} from 'react-redux'
import DateInputWeek from '../DateInputWeek'
import moment from 'moment'
import SelectedBuildingsLegend from './SelectedBuildingsLegend'
import {VENDOR_SERVICE_CANCELLED, VENDOR_SERVICE_DECLINED, VENDOR_SERVICE_VENDOR_NO_SHOW} from '../../constants/VendorBookingConstants'
import DateInputWithArrows from '../DateInputWithArrows'
import DashboardToggle from '../DashboardToggle'
import DayCalendar from './DayCalendar'
import ServiceAvailabilityProvider from './ServiceAvailabilityProvider'

const MODE_WEEK = 'week'
const MODE_DAY = 'day'

function CalendarPage() {
  const classes = useStyles()
  const buildingIds = useSelector(state => state.User.buildingIds)
  const appointmentsLookup = useSelector(state => state.Appointments.appointmentsLookup)
  const [mode, setMode] = useState(MODE_DAY)

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedBuildings, setSelectedBuildings] = useState(buildingIds)

  const startOfRange = moment(selectedDate).startOf(mode).toDate()
  const endOfRange = moment(selectedDate).endOf(mode).toDate()
  const appointments = Object.values(appointmentsLookup).filter(
    a => selectedBuildings.includes(a.buildingId) && a.startAt >= startOfRange && a.startAt <= endOfRange && shouldShow(a.status),
  )

  return (
    <div style={Layout.PageContainer}>
      <PageHeader title={'Calendar'} />
      <div className={classes.mainContainer}>
        <div className={classes.controls}>
          <div className={classes.row}>
            <SelectedBuildingsLegend selected={selectedBuildings} onChange={setSelectedBuildings} />

            <div style={{marginLeft: Spacing.spaceMedium}}>
              <DashboardToggle
                value={mode === MODE_WEEK}
                onChange={isSelected => setMode(isSelected ? MODE_WEEK : MODE_DAY)}
                label={`Show ${mode === MODE_WEEK ? MODE_DAY : MODE_WEEK} view`}
              />
            </div>
          </div>
          {mode === MODE_DAY ? (
            <DateInputWithArrows date={selectedDate} onChange={setSelectedDate} />
          ) : (
            <DateInputWeek date={selectedDate} onChange={setSelectedDate} />
          )}
        </div>
        <ServiceAvailabilityProvider appointments={appointments} selectedBuildingIds={selectedBuildings}>
          {mode === MODE_WEEK ? <WeekCalendar date={selectedDate} /> : <DayCalendar date={selectedDate} />}
        </ServiceAvailabilityProvider>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: Colors.rxrContainerColor,
    flex: 1,
    width: '100%',
    overflowX: 'auto',
    paddingTop: Spacing.spaceMedium,
  },

  controls: {
    padding: `0 ${Spacing.spaceLarge}px ${Spacing.spaceLarge}px`,
  },

  row: {
    display: 'flex',
    marginBottom: Spacing.spaceMedium,
    justifyContent: 'space-between',
  },
}))

export default CalendarPage

function shouldShow(status) {
  return ![VENDOR_SERVICE_CANCELLED, VENDOR_SERVICE_DECLINED, VENDOR_SERVICE_VENDOR_NO_SHOW].includes(status)
}
