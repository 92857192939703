import * as Colors from '../../../../src/assets/styles/color'
import * as Spacing from '../../../../src/assets/styles/spacing'
import * as OldTypography from '../../../../src/assets/styles/typography'
import * as Layout from './layout'
import {constructClassString} from '../../../../src/Utils/objectUtil'

const Typography = {
  ...OldTypography,
  fontSizeExtraLarge: 30,
  fontSizeLarge: 24,
  fontSizeMediumLarge: 20,
  fontSizeMedium: 16,
  fontSizeSmall: 14,
  fontSizeExtraSmall: 12,
  fontSizeFooter: 10,
}

const boxShadowSmall = '1px 1px 5px 0px rgba(0, 0, 0, 0.15)'
const boxShadowMedium = '0px 0px 10px 1px rgba(0, 0, 0, 0.15)'

export {Colors, Spacing, Typography, Layout, constructClassString, boxShadowMedium, boxShadowSmall}
