import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class VendorAppointment extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getVendorAppointment(input, options) {
    return this._get(
       'getVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getVendorAppointment(input, options) {
    return new this().getVendorAppointment(input, options)
  }

  /**
   * @param { {filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorAppointments(input, options) {
    return this._list(
       'listVendorAppointments',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorAppointments(input, options) {
    return new this().listVendorAppointments(input, options)
  }

  /**
   * @param { {filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorAppointments(input, options) {
    return this._iterate(
       'listVendorAppointments',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorAppointments(input, options) {
    return new this().iterateVendorAppointments(input, options)
  }

  /**
   * @param { {foreignAppointmentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorAppointmentsByForeignAppointment(input, options) {
    return this._list(
       'listVendorAppointmentsByForeignAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {foreignAppointmentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorAppointmentsByForeignAppointment(input, options) {
    return new this().listVendorAppointmentsByForeignAppointment(input, options)
  }

  /**
   * @param { {foreignAppointmentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorAppointmentsByForeignAppointment(input, options) {
    return this._iterate(
       'listVendorAppointmentsByForeignAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {foreignAppointmentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorAppointmentsByForeignAppointment(input, options) {
    return new this().iterateVendorAppointmentsByForeignAppointment(input, options)
  }

  /**
   * @param { {status?: ReservationState, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorAppointmentsByStatus(input, options) {
    return this._list(
       'listVendorAppointmentsByStatus',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {status?: ReservationState, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorAppointmentsByStatus(input, options) {
    return new this().listVendorAppointmentsByStatus(input, options)
  }

  /**
   * @param { {status?: ReservationState, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorAppointmentsByStatus(input, options) {
    return this._iterate(
       'listVendorAppointmentsByStatus',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {status?: ReservationState, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorAppointmentsByStatus(input, options) {
    return new this().iterateVendorAppointmentsByStatus(input, options)
  }

  /**
   * @param { {residentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorAppointmentsByResident(input, options) {
    return this._list(
       'listVendorAppointmentsByResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {residentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorAppointmentsByResident(input, options) {
    return new this().listVendorAppointmentsByResident(input, options)
  }

  /**
   * @param { {residentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorAppointmentsByResident(input, options) {
    return this._iterate(
       'listVendorAppointmentsByResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {residentId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorAppointmentsByResident(input, options) {
    return new this().iterateVendorAppointmentsByResident(input, options)
  }

  /**
   * @param { {buildingId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorAppointmentsByBuilding(input, options) {
    return this._list(
       'listVendorAppointmentsByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorAppointmentsByBuilding(input, options) {
    return new this().listVendorAppointmentsByBuilding(input, options)
  }

  /**
   * @param { {buildingId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorAppointmentsByBuilding(input, options) {
    return this._iterate(
       'listVendorAppointmentsByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorAppointmentsByBuilding(input, options) {
    return new this().iterateVendorAppointmentsByBuilding(input, options)
  }

  /**
   * @param { {vendorServiceId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listVendorAppointmentsByVendorService(input, options) {
    return this._list(
       'listVendorAppointmentsByVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {vendorServiceId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listVendorAppointmentsByVendorService(input, options) {
    return new this().listVendorAppointmentsByVendorService(input, options)
  }

  /**
   * @param { {vendorServiceId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateVendorAppointmentsByVendorService(input, options) {
    return this._iterate(
       'listVendorAppointmentsByVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {vendorServiceId?: ID, startAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateVendorAppointmentsByVendorService(input, options) {
    return new this().iterateVendorAppointmentsByVendorService(input, options)
  }

  /**
   * @param { {input: CreateNewVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createNewVendorAppointment(input, options) {
    return this._mutate(
       'createNewVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateNewVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createNewVendorAppointment(input, options) {
    return new this().createNewVendorAppointment(input, options)
  }

  /**
   * @param { {input: RescheduleVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async rescheduleExistingVendorAppointment(input, options) {
    return this._mutate(
       'rescheduleExistingVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: RescheduleVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async rescheduleExistingVendorAppointment(input, options) {
    return new this().rescheduleExistingVendorAppointment(input, options)
  }

  /**
   * @param { {input: CancelVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async cancelExistingVendorAppointment(input, options) {
    return this._mutate(
       'cancelExistingVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CancelVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async cancelExistingVendorAppointment(input, options) {
    return new this().cancelExistingVendorAppointment(input, options)
  }

  /**
   * @param { {input: CreateVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createVendorAppointment(input, options) {
    return this._mutate(
       'createVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createVendorAppointment(input, options) {
    return new this().createVendorAppointment(input, options)
  }

  /**
   * @param { {input: UpdateVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateVendorAppointment(input, options) {
    return this._mutate(
       'updateVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateVendorAppointment(input, options) {
    return new this().updateVendorAppointment(input, options)
  }

  /**
   * @param { {input: DeleteVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteVendorAppointment(input, options) {
    return this._mutate(
       'deleteVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteVendorAppointment(input, options) {
    return new this().deleteVendorAppointment(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "VendorAppointment"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    Object.entries(VendorAppointment._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = VendorAppointment.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })


    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof VendorAppointment.custom_afterFind === 'function') {
      return VendorAppointment.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { VendorAppointment }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
VendorAppointment._complexFields = {"vendorService":"VendorService","building":"Building","resident":"Resident","addOns":"[VendorAppointmentAddOn]","paymentMethod":"PaymentMethod","payment":"Payment","amenityReservation":"AmenityReservation","recurringVendorAppointment":"RecurringVendorAppointment","petProfiles":"[PetProfileVendorAppointment]","vendorServiceCredits":"[VendorServiceCredit]"}
VendorAppointment._jsonFields = []

VendorAppointment.FragmentDefault = `
fragment VendorAppointmentFragmentDefault on VendorAppointment {
  id
  vendorServiceId
  foreignAppointmentId
  buildingId
  unitId
  residentId
  status
  reminderSentAt
  startAt
  endAt
  notes
  cost
  paymentMethodId
  paymentId
  amenityReservationId
  recurringVendorAppointmentId
  yardiBatchId
  createdAt
  updatedAt
}

`

VendorAppointment.WithResident = `
fragment VendorAppointmentWithResident on VendorAppointment {
  id
  status
  startAt
  endAt
  vendorServiceId
  buildingId
  addOns {
    label
    cost
  }
  resident {
    id
    displayName
    phoneNumber
    email
    occupancy {
      unit {
        number
      }
    }
  }
}

`


export default VendorAppointment
