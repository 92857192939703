import {styled} from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {rxrTealColor, rxrDarkGreyColor} from '../assets/styles/color'
import React from 'react'

// Customizing the Tab component using the styled utility
const AntTab = styled(props => <Tab disableRipple {...props} />)(({theme}) => ({
  textTransform: 'none',
  minWidth: 150,
  fontSize: 18,
  fontWeight: 700,
  '&:hover': {
    color: rxrTealColor,
    opacity: 1,
  },
  '&:focus': {
    color: rxrTealColor,
    outline: 'none',
  },
  '&.Mui-selected': {
    color: rxrTealColor,
    fontWeight: 700,
  },
}))

// Customizing the Tabs component
const AntTabs = styled(Tabs)(({theme}) => ({
  indicator: {
    backgroundColor: rxrTealColor,
    borderBottom: `4px solid ${rxrTealColor}`,
  },
}))

export {AntTab, AntTabs}
