import {rxrWhiteColor, rxrBlackColor, rxrDarkGreyColor, rxrBlueColor, rxrTealColor} from './color'
import {spaceMedium, spaceSmall} from './spacing'

export const fontFamilyDefault = "'proxima_nova_rgregular', 'Roboto', sans-serif"
export const heavyFontWeight = 700
export const mediumFontWeight = 500
export const lightFontWeight = 400
export const extraLightFontWeight = 300

export const fontSizeExtraLarge = 22
export const fontSizeLarge = 18
export const fontSizeMediumLarge = 16
export const fontSizeMedium = 14
export const fontSizeMediumSmall = 13
export const fontSizeSmall = 12
export const fontSizeExtraSmall = 10
export const fontSizeExtraExtraSmall = 10

export const H3Header = {
  fontWeight: 'bold',
  fontSize: fontSizeLarge,
  color: rxrBlackColor,
  fontStyle: 'normal',
}

export const H4HeaderBlack = {
  fontWeight: 'bold',
  fontSize: fontSizeMediumLarge,
  color: rxrBlackColor,
  fontStyle: 'normal',
  paddingBottom: spaceSmall,
}

export const H4Header = {
  fontWeight: 'bold',
  fontSize: fontSizeMediumLarge,
  color: rxrWhiteColor,
  fontStyle: 'normal',
  display: 'flex',
  alignItems: 'center',
}

export const H4HeaderWithoutBold = {
  fontSize: fontSizeMediumLarge,
  color: rxrWhiteColor,
  display: 'flex',
  alignItems: 'center',
}

export const BodyHeader = {
  fontSize: fontSizeMediumLarge,
  color: rxrBlackColor,
}

export const CaptionHeader = {
  lineHeight: '14px',
  color: rxrBlackColor,
}

export const MorningHeaderBlack = {
  fontSize: fontSizeMedium,
  color: rxrBlackColor,
}

export const MenuItemSelected = {
  fontSize: fontSizeMediumLarge,
  color: 'rgba(0, 0, 0, 0.87)',
}

export const MenuItemNotSelected = {
  fontSize: fontSizeMediumLarge,
  color: rxrDarkGreyColor,
}

export const AppBarHeader = {
  fontSize: 40,
  color: rxrBlueColor,
  fontWeight: 'bold',
  fontStyle: 'normal',
}

export const TrademarkText = {
  fontSize: fontSizeMediumLarge,
  color: rxrBlackColor,
  fontWeight: 900,
  fontStyle: 'normal',
  marginBottom: spaceSmall,
}

export const evenTableRow = {
  fontSize: fontSizeMediumLarge,
  color: rxrTealColor,
}

export const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const KYCHeaderText = {
  fontSize: fontSizeExtraLarge,
  lineHeight: '24px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  color: rxrWhiteColor,
}

export const KYCHeaderSubText = {
  fontSize: 18,
  lineHeight: '18px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  color: rxrWhiteColor,
}

export const KYCSubHeaderTitle = {
  fontSize: fontSizeMediumLarge,
  lineHeight: '16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: '0.8px',
  textTransform: 'uppercase',
  color: rxrBlueColor,
}
export const residentProfileTabHeading = {
  marginBottom: '32px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '14px',
  color: rxrTealColor,
}

export const navigationButtonWithArrow = {
  fontSize: fontSizeMediumLarge,
  color: rxrBlueColor,
  fontWeight: 'bold',
}

export const bodyText = {
  fontSize: fontSizeMediumSmall,
  color: rxrBlackColor,
  fontStyle: 'normal',
  paddingBottom: spaceSmall,
}

export const subBodyText = {
  fontSize: fontSizeExtraSmall,
  color: rxrDarkGreyColor,
  fontStyle: 'normal',
  paddingBottom: spaceSmall,
}

export const totalText = {
  fontSize: 15,
  color: rxrBlackColor,
  fontStyle: 'normal',
  fontWeight: 'bold',
  paddingBottom: spaceMedium,
}

export const NoteCardTitle = {
  fontSize: fontSizeSmall,
  color: rxrDarkGreyColor,
}
