import React, {useRef, useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import TextInput from '../TextInput'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import MultilineTextInput from '../MultilineTextInput'
import DateInput from '../DateInput'
import SelectInput from '../SelectInput'
import moment from 'moment'
import {loadAvailability, rescheduleExistingAppointment, cancelExistingAppointment} from '../../lib/queries'
import useFormChanged, {DateObjectValidator} from '../../hooks/useFormChanged'
import RXRButton from '../RXRButton'
import {refreshAppointment} from '../../actions/appointmentActions'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'

const FormValidation = useFormChanged.PropLevelValidation({
  startAt: DateObjectValidator,
  endAt: DateObjectValidator,
})

const EMPTY_FORM = {
  vendorServiceId: null,
  addOns: [],
  notes: '',
  startAt: null,
  endAt: null,
}

function VendorAppointmentForm(props) {
  const servicesLookup = useSelector(state => state.User.servicesLookup)
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)
  const appointmentsLookup = useSelector(state => state.Appointments.appointmentsLookup)

  const dispatch = useDispatch()

  // we copy the appointmentId so we can modify it without overwriting it during a refresh
  const {validateForm, invalidItems, changedItems, resetInitialForm, form, setForm} = useFormChanged(EMPTY_FORM, FormValidation)

  // selectedDate is a little overloaded, this will only be set if they CHANGE the date
  const [selectedDate, setSelectedDate] = useState(null)
  const requestedDateRef = useRef(selectedDate)
  const [startTimeBlocks, setStartTimeBlocks] = useState([])
  const [isLoadingAvailability, setIsLoadingAvailability] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [confirmDelete, setConfrimDelete] = useState(false)

  const thisService = servicesLookup[form.vendorServiceId]
  const thisAppointment = appointmentsLookup[props.appointmentId]

  useEffect(() => {
    const app = appointmentsLookup[props.appointmentId]
    resetInitialForm(app)
    // by setting this to null we indicate that they use has not changed the date (yet)
    setSelectedDate(null)
    setStartTimeBlocks([app.startAt.toISOString()])
  }, [props.appointmentId])

  /**
   * @param {*} newData
   */
  const handleUpdateForm = newData => {
    setForm(newData)
    props.onUpdateForm({...form, ...newData})
  }

  /**
   * @param {Date} date
   * @returns {Promise<void>}
   */
  const handleChangeDate = async date => {
    handleUpdateForm({startAt: null, endAt: null})
    setIsLoadingAvailability(true)
    requestedDateRef.current = date
    setStartTimeBlocks([])

    try {
      const blocks = await loadAvailability(form.vendorServiceId, date)

      // if the requested date has changed, we ignore this response
      if (requestedDateRef.current !== date) {
        return
      }
      setSelectedDate(date)
      setStartTimeBlocks(blocks.map(b => b.start))
    } catch (err) {
      window.alert(err.message)
    }

    setIsLoadingAvailability(false)
  }

  /**
   * @param {string} blockStart
   */
  const handleChangeTimeBlock = blockStart => {
    handleUpdateForm({startAt: new Date(blockStart), endAt: moment(blockStart).add(thisService.duration, 'minutes').toDate()})
  }

  /**
   * @param {string} startAt
   * @returns {string|null}
   */
  const getTimeBlockFromStartTime = startAt => {
    if (!startAt) {
      return null
    }

    const startStr = moment(startAt).format('HH:mma')
    const endStr = moment(startAt).add(thisService.duration, 'minutes').format('HH:mma')

    return `${startStr} - ${endStr}`
  }

  const saveChanges = async () => {
    if (!validateForm()) {
      return
    }
    setIsSaving(true)

    try {
      await rescheduleExistingAppointment(form)
      await refreshAppointment(dispatch, form.id)
      props.onSaveComplete(form)
    } catch (err) {
      window.alert(err.message)
    }

    setIsSaving(false)
  }

  const cancelAppointment = async () => {
    setIsSaving(true)

    try {
      await cancelExistingAppointment(form.id)
      await refreshAppointment(dispatch, form.id)
      props.onSaveComplete(form)
    } catch (err) {
      window.alert(err.message)
    }

    setIsSaving(false)
  }

  if (!thisService || !form.id) {
    return null
  }

  const startTimeOptions = startTimeBlocks.map(start => ({value: start, label: getTimeBlockFromStartTime(start)}))

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <TextInput label={'Service'} value={thisService.label} isDisabled={true} />
      </Grid>

      <Grid item sm={12}>
        <TextInput label={'Add-ons'} value={form.addOns.join(', ') || 'none'} isDisabled={true} />
      </Grid>

      <Grid item sm={12}>
        <TextInput
          label={'Resident and unit'}
          value={`${thisAppointment.resident.displayName}, ${thisAppointment.resident.unitNumber}, ${
            buildingsLookup[thisAppointment.buildingId].displayName
          }`}
          isDisabled={true}
        />
      </Grid>

      <Grid item sm={12}>
        <MultilineTextInput label={'Notes'} value={form.notes || 'none'} isDisabled={true} />
      </Grid>

      <Grid item sm={12}>
        <DateInput
          label={'Date'}
          value={selectedDate || form.startAt}
          onChange={handleChangeDate}
          isRequired={true}
          error={invalidItems.includes('startAt')}
        />
      </Grid>

      <Grid item sm={12}>
        <SelectInput
          label={'Time'}
          isRequired={true}
          isLoading={isLoadingAvailability}
          options={startTimeOptions}
          disabled={!selectedDate}
          value={form.startAt ? form.startAt.toISOString() : null}
          onChange={handleChangeTimeBlock}
          error={invalidItems.includes('startAt')}
        />
      </Grid>

      <Grid item xs={6}>
        <RXRButton isLoading={isSaving} onClick={saveChanges} disabled={changedItems.length === 0}>
          Confirm reschedule
        </RXRButton>
      </Grid>
      <Grid item xs={6} style={{textAlign: 'right'}}>
        <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} isLoading={isSaving} onClick={() => setConfrimDelete(true)}>
          Cancel booking
        </RXRButton>
      </Grid>
      <Grid item xs={12}>
        <RXRButton type={RXRButton.TYPE_TEXT} isLoading={isSaving} onClick={props.onCancel}>
          Close
        </RXRButton>
      </Grid>

      <ConfirmDeleteDialog
        isOpen={confirmDelete}
        onCancel={() => setConfrimDelete(false)}
        onConfirmDelete={cancelAppointment}
        prompt="Are you sure you want to cancel this reservation?"
        cancelButtonText="No, return"
        deleteButtonText="Yes, cancel"
      />
    </Grid>
  )
}

VendorAppointmentForm.propTypes = {
  appointmentId: PropTypes.string.isRequired,
  onUpdateForm: PropTypes.func.isRequired,
  onSaveComplete: PropTypes.func.isRequired,
}

export default VendorAppointmentForm
2
