import ActionTypes from '../actions/types'

/**
 * @typedef {Object} BuildingData
 * @property {string} id
 * @property {string} displayName
 */

/**
 * @typedef {Object} ServiceData
 * @property {string} id
 * @property {string} label
 * @property {string} buildingId
 */

/**
 * @typedef {Object} AuthState
 * @property {string} vendorId
 * @property {Object<string, BuildingData>} buildingsLookup
 * @property {Array<string>} buildingIds
 * @property {Object<string, ServiceData>} servicesLookup
 * @property {Array<string>} serviceIds
 * @property {Object<string, Array<string>>} serviceIdsByBuilding
 * @property {boolean} isInitialized
 */

const initialState = {
  vendorId: null,
  buildingsLookup: {},
  buildingIds: [],
  servicesLookup: {},
  serviceIds: [],
  serviceIdsByBuilding: {},
  isInitialized: false,
}

// --------------------------------------------------------------------------------
// State mutation functions:

/**
 * @param {AuthState} state
 * @param {string|null} vendorId
 * @param {Array<BuildingData>} buildings
 * @param {Array<ServiceData>} services
 * @returns {AuthState}
 */
function handleSetUser(state, {vendorId, buildings, services}) {
  const buildingsLookup = {}
  const buildingIds = []

  buildings.forEach(building => {
    buildingsLookup[building.id] = building
    buildingIds.push(building.id)
  })

  const servicesLookup = {}
  const serviceIds = []
  const serviceIdsByBuilding = {}
  services.forEach(service => {
    servicesLookup[service.id] = service
    serviceIds.push(service.id)
    serviceIdsByBuilding[service.buildingId] = serviceIdsByBuilding[service.buildingId] || []
    serviceIdsByBuilding[service.buildingId].push(service.id)
  })

  return {
    ...state,
    vendorId: vendorId,
    buildingsLookup: buildingsLookup,
    buildingIds: buildingIds,
    servicesLookup: servicesLookup,
    serviceIds: serviceIds,
    serviceIdsByBuilding: serviceIdsByBuilding,
    isInitialized: true,
  }
}

// --------------------------------------------------------------------------------

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return handleSetUser(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
