import React, {createContext, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {loadVendorServiceResourceSettings, updateVendorServiceResourceSettings} from '../../lib/queries'
import {useSelector} from 'react-redux'
import {LOADING_STATE_ERROR, LOADING_STATE_LOADING} from '../../constants/AppConstants'
import SimpleSpinner from '../../../../src/components/SimpleSpinner'
import EmptyStateIconAndMessage from '../../../../src/components/EmptyStateIconAndMessage'
import RXRIcon from '../../../../src/components/RXRIcon'
import {makeStyles} from '@mui/styles'

export const ServiceAvailabilityContext = createContext({})

function ServiceAvailabilityProvider(props) {
  const classes = useStyles()
  const [isDataFetching, setIsDataFetching] = useState(true)
  const [closuresLookup, setClosuresLookup] = useState({})
  const [generalCalendarAvailability, setGeneralCalendarAvailability] = useState({})
  const buildingResourceSettingsCache = useRef({})
  const serviceIdsByBuilding = useSelector(state => state.User.serviceIdsByBuilding)
  const loadingStatus = useSelector(state => state.Appointments.loading.status)

  useEffect(() => {
    loadVendorServiceResourceSettingsForSelectedBuildings().then()
  }, [props.selectedBuildingIds])

  async function loadVendorServiceResourceSettingsForSelectedBuildings() {
    setIsDataFetching(true)

    // first, we load the settings for each selected building (if not already loaded)
    await Promise.all(
      props.selectedBuildingIds.map(async buildingId => {
        if (!buildingResourceSettingsCache.current[buildingId]) {
          const thisBuildingSettings = await loadVendorServiceResourceSettings(serviceIdsByBuilding[buildingId][0])
          // this function updates the buildingResourceSettingsCache.current cache
          handleUpdatedResourceSettingsForBuilding(thisBuildingSettings, buildingId)
        }
      }),
    )

    // store a map from buildingid <> general availability array for convenience
    const buildingToGAMap = props.selectedBuildingIds.reduce((acc, b) => {
      acc[b] = buildingResourceSettingsCache.current[b].generalAvailability
      return acc
    }, {})

    // we determine each calendar day's start/end time based on the general availability of all selected buildings
    /** @type {Array<{start: number, end: number}>} */
    const byDayOfWeekStartAndEnd = [...new Array(7)].map((_, dow) => ({
      start: Math.min(1440, ...Object.values(buildingToGAMap).flatMap(ga => ga.filter(g => g.dayOfWeek === dow).map(g => g.startTime))),
      end: Math.max(0, ...Object.values(buildingToGAMap).flatMap(ga => ga.filter(g => g.dayOfWeek === dow).map(g => g.endTime))),
    }))

    // the calendar bounds are the earliest start time and latest end time of all selected buildings across all days
    const calendarBounds = {
      start: Math.min(1440, ...byDayOfWeekStartAndEnd.map(dayBounds => dayBounds.start)),
      end: Math.max(0, ...byDayOfWeekStartAndEnd.map(dayBounds => dayBounds.end)),
    }

    setGeneralCalendarAvailability({
      // start and end is the week wide, building wide boundaries
      start: calendarBounds.start,
      end: calendarBounds.end,
      // we then also save it by day of week for convenience
      byDayOfWeekStartAndEnd: byDayOfWeekStartAndEnd,
    })

    setIsDataFetching(false)
  }

  /**
   * @param {*} newClosure
   * @param {boolean?} isDeleted
   * @returns {Promise<void>}
   */
  async function createOrUpdateClosure(newClosure, isDeleted = false) {
    // start with our last loaded state
    const updateInput = {...buildingResourceSettingsCache.current[newClosure.buildingId]}

    const thisClosure = closuresLookup[newClosure.id]

    if (thisClosure) {
      const otherClosures = updateInput.closures.filter(c => c.id !== thisClosure.id)

      if (isDeleted) {
        // we must remove it, keeping only the other closures
        updateInput.closures = otherClosures
      } else {
        // we update it
        updateInput.closures = [
          ...otherClosures,
          {id: thisClosure.id, label: newClosure.notes, startAt: newClosure.startAt.toISOString(), endAt: newClosure.endAt.toISOString()},
        ]
      }
    } else {
      // for new closures, we must create it at every building
      updateInput.closures = [
        ...updateInput.closures,
        {label: newClosure.notes, startAt: newClosure.startAt.toISOString(), endAt: newClosure.endAt.toISOString()},
      ]
    }

    const response = await updateVendorServiceResourceSettings(updateInput)
    // need to pass reset = true so that we are able to remove references to deleted closures
    handleUpdatedResourceSettingsForBuilding(response, newClosure.buildingId, true)
  }

  /**
   * @param {VendorServiceResourceSettings} settings
   * @param {string} buildingId
   * @param {boolean?} reset
   */
  function handleUpdatedResourceSettingsForBuilding(settings, buildingId, reset = false) {
    // save to cache
    buildingResourceSettingsCache.current[buildingId] = settings

    const closuresLookupForCurrentBuilding = settings.closures.reduce((acc, c) => {
      // we need to add the buildingIds array here because the TimeBlockOff form is expecting it
      // and we add the single buildingId for convenience (used in DayCalendar -> getColumnStartAndEndTimes)
      acc[c.id] = {...c, buildingIds: [buildingId], buildingId: buildingId}
      return acc
    }, {})

    setClosuresLookup(before => {
      if (reset) {
        return {
          ...Object.values(before)
            .filter(c => c.buildingId !== buildingId)
            .reduce((agr, c) => {
              agr[c.id] = c
              return agr
            }, {}),
          ...closuresLookupForCurrentBuilding,
        }
      }
      Object.keys(closuresLookupForCurrentBuilding).forEach(closureId => {
        if (before[closureId]) {
          before[closureId].buildingIds = [...new Set([...before[closureId].buildingIds, buildingId])]
          delete closuresLookupForCurrentBuilding[closureId]
        }
      })

      return {...before, ...closuresLookupForCurrentBuilding}
    })
  }

  return (
    <ServiceAvailabilityContext.Provider
      value={{
        buildingIds: props.selectedBuildingIds || [],
        appointments: props.appointments,
        isDataFetching,
        closuresLookup,
        generalCalendarAvailability,
        buildingResourceSettingsCache,

        createOrUpdateClosure,
      }}
    >
      <div className={classes.container}>
        {loadingStatus === LOADING_STATE_LOADING || isDataFetching ? (
          <div className={classes.loading}>
            <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
          </div>
        ) : loadingStatus === LOADING_STATE_ERROR ? (
          <div className={classes.loading}>
            <EmptyStateIconAndMessage message={'Error loading appointments'} icon={RXRIcon.CLOSE} />
          </div>
        ) : (
          props.children
        )}
      </div>
    </ServiceAvailabilityContext.Provider>
  )
}

ServiceAvailabilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  selectedBuildingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  appointments: PropTypes.array.isRequired,
}

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  loading: {
    textAlign: 'center',
  },
}))

export default ServiceAvailabilityProvider
