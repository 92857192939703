import {createTheme} from '@mui/material/styles'
import {blue, blueGrey} from '@mui/material/colors'

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ['proxima_nova_regular'],
  },
  palette: {
    primary: blue,
    secondary: blueGrey,
  },
  breakpoints: {
    values: {
      xs: 0, // small
      sm: 600, // medium
      md: 960, // large
      lg: 1280, // extra large
      xl: 1920, // extra extra large
    },
  },
})

export default theme
