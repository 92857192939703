import {fadeColor} from '../Utils/colorUtils'
import {useEffect, useRef, useState} from 'react'
import useBuildingColors from './useBuildingColors'
import useServiceAvailabilityContext from './useServiceAvailabilityContext'

/**
 * @param {{
 *   getColumnStartAndEndTimes: function({startAt: Date|string, endAt: Date|string}, *): {column: string, start: number, end: number},
 *   getClosureContent: function(ResourceClosure): string|JSXElement,
 *   getAppointmentContent: function(AppointmentData): string|JSXElement,
 * }} props
 * @returns {{
 *     editingEvent: {current: string},
 *     editingClosure: {current: string},
 *     events: Array<CalendarEventConfig>,
 *     closuresLookup: Object<string, Array<ResourceClosure>>
 *     closureEvents: Array<CalendarEventConfig>,
 *
 *     handleUpdateClosure: function(ResourceClosure): void,
 *     handleUpdateAppointment: function(AppointmentData): void,
 *     resetAppointmentEvents: function(): void,
 *     resetClosureEvents: function(): void,
 * }}
 */
function useSharedCalendarLogic(props) {
  const {appointments, buildingIds, closuresLookup} = useServiceAvailabilityContext()

  const editingEvent = useRef()
  const editingClosure = useRef()
  const getBuildingColor = useBuildingColors()

  /** @type {Array<CalendarEventConfig>} events */
  const [events, setEvents] = useState([])

  /** @type {Array<CalendarEventConfig>} events */
  const [closureEvents, setClosureEvents] = useState([])

  useEffect(() => {
    resetAppointmentEvents()
  }, [appointments])

  useEffect(() => {
    resetClosureEvents()
  }, [closuresLookup, buildingIds])

  /**
   * @param {*} newData
   */
  const handleUpdateAppointment = newData => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    // update our reservation event accordingly
    const e = events.find(e => e.id === newData.id)
    if (e) {
      editingEvent.current = newData.id
      Object.assign(e, props.getColumnStartAndEndTimes(newData))
      setEvents([...events])
    }
  }

  const handleUpdateClosure = newData => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    // update our closure event accordingly
    const e = closureEvents.find(e => e.id === newData.id)
    if (e) {
      editingClosure.current = newData.id
      Object.assign(e, props.getColumnStartAndEndTimes(newData))
      setClosureEvents([...closureEvents])
    }
  }

  function resetClosureEvents() {
    const splitClosureIntoDays = closure => {
      const events = []
      const start = new Date(closure.startAt)
      const end = new Date(closure.endAt)

      for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
        const eventStart = new Date(date)
        const eventEnd = new Date(date)

        if (date.toDateString() === start.toDateString()) {
          eventStart.setHours(start.getHours(), start.getMinutes(), start.getSeconds())
        } else {
          eventStart.setHours(0, 0, 0)
        }

        if (date.toDateString() === end.toDateString()) {
          eventEnd.setHours(end.getHours(), end.getMinutes(), end.getSeconds())
        } else {
          eventEnd.setHours(23, 59, 59)
        }

        const selectedAssociatedBuildingIds = closure.buildingIds.filter(b => buildingIds.includes(b))

        events.push({
          id: closure.id, // TODO: closures that span multiple days would have the same id and will probably break things
          color: fadeColor(
            selectedAssociatedBuildingIds.length === 1 ? getBuildingColor(selectedAssociatedBuildingIds[0]) : '#000000',
            0.5,
          ),
          startAt: eventStart.toISOString(),
          endAt: eventEnd.toISOString(),
          ...props.getColumnStartAndEndTimes({startAt: eventStart.toISOString(), endAt: eventEnd.toISOString()}, closure),
          content: props.getClosureContent(closure),
        })
      }
      return events
    }
    setClosureEvents(Object.values(closuresLookup).flatMap(c => splitClosureIntoDays(c)))
  }

  function resetAppointmentEvents() {
    setEvents(
      appointments.map(a => {
        return {
          id: a.id,
          color: getBuildingColor(a.buildingId),
          ...props.getColumnStartAndEndTimes(a, a),
          content: props.getAppointmentContent(a),
        }
      }),
    )
  }

  return {
    editingEvent,
    editingClosure,
    events,
    closuresLookup,
    closureEvents,

    handleUpdateClosure,
    handleUpdateAppointment,
    resetAppointmentEvents,
    resetClosureEvents,
  }
}

export default useSharedCalendarLogic
