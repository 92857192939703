import React, {useState} from 'react'
import {Dialog, DialogActions, DialogTitle, IconButton, DialogContent, DialogContentText} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Colors, Spacing, Typography} from '../../../../src/assets/styles'
import RXRButton from '../RXRButton'
import RXRIcon from '../RXRIcon'

const Constants = {
  BTN_SUBMIT: 'Resend',
  MESSAGE: 'Are you sure you want to send a new verification code?',
}

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'relative',
    maxWidth: '286px',
    '& .MuiDialogActions-root': {
      padding: '0px 8px 16px 16px',
      justifyContent: 'flex-start',
    },
  },
  closeButton: {
    cursor: 'pointer',
    height: 34,
    width: 34,
    borderRadius: '50% !important',
    lineHeight: 0,
    '&:focus': {
      outline: 'none',
    },
    position: 'absolute !important',
    right: Spacing.spaceExtraSmall,
    top: Spacing.spaceExtraSmall,
  },
  messageText: {
    fontSize: Typography.fontSizeMediumLarge,
    lineHeight: '18.75px',
    color: Colors.rxrBlackColor,
  },

  actions: {
    marginTop: Spacing.spaceSmall,
    justifyContent: 'center !important',
  },
}))

function ResendCodeModal(props) {
  const classes = useStyles()

  let handleSubmit = () => {
    props.handleSubmit().then(() => props.handleClose())
  }

  let handleClose = () => {
    props.handleClose()
  }

  return (
    <React.Fragment>
      <Dialog open={props.isOpen} onClose={handleClose} classes={{paper: classes.dialog}}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <RXRIcon icon={RXRIcon.CLOSE} />
        </IconButton>
        <DialogTitle>Resend Code</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.messageText}>{Constants.MESSAGE}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <RXRButton variant="contained" onClick={handleSubmit}>
            {Constants.BTN_SUBMIT}
          </RXRButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ResendCodeModal
