/*
This code repurposed from https://codesandbox.io/s/popper-with-arrow-58jhe?file=/src/RichTooltip.tsx
*/

import React, {useState} from 'react'
import {Box, ClickAwayListener, Fade, Paper, Popper} from '@mui/material'
import {makeStyles} from '@mui/styles'
import styled from '@mui/material/styles/styled'
import PropTypes from 'prop-types'
import {Colors, Spacing} from '../assets/styles'

const arrowTipSize = 24
const arrowBaseSize = arrowTipSize / Math.sqrt(2) /* = width / sqrt(2) = (length of the hypotenuse) */

const StyledPopper = styled(Popper)({
  zIndex: 100,
  '&[data-popper-placement*="bottom"] .MuiPopper-arrow': {
    top: 0,
    left: 0,
    marginTop: -arrowBaseSize,
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 100%',
    },
  },
  '&[data-popper-placement*="top"] .MuiPopper-arrow': {
    bottom: 0,
    left: 0,
    marginBottom: -arrowBaseSize,
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% 0',
    },
  },
  '&[data-popper-placement*="right"] .MuiPopper-arrow': {
    left: 0,
    marginLeft: -arrowBaseSize,
    height: arrowTipSize,
    width: arrowBaseSize,
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '100% 100%',
    },
  },
  '&[data-popper-placement*="left"] .MuiPopper-arrow': {
    right: 0,
    marginRight: -arrowBaseSize,
    height: arrowTipSize,
    width: arrowBaseSize,
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '0 0',
    },
  },
})

const RichTooltip = props => {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = useState(null)
  return (
    <StyledPopper
      open={props.open}
      anchorEl={props.anchorEl}
      placement={props.placement}
      transition
      className={classes.popper}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {name: 'arrow', enabled: true, options: {element: arrowRef}},
      ]}
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <span className={[classes.arrow, 'MuiPopper-arrow'].join(' ')} ref={setArrowRef} />
            <ClickAwayListener
              /* @see https://github.com/mui/material-ui/issues/12034 */
              mouseEvent="onMouseUp"
              onClickAway={props.onClose}
            >
              <Box className={classes.content}>{props.children}</Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </StyledPopper>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    padding: Spacing.spaceMedium,
    backgroundColor: Colors.rxrWhiteColor,
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: arrowTipSize,
    height: arrowTipSize,
    boxSizing: 'border-box',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.15)',
      backgroundColor: Colors.rxrWhiteColor,
      transform: 'rotate(45deg)',
    },
  },
}))

RichTooltip.propTypes = {
  anchorEl: PropTypes.any,
  placement: PropTypes.string, // one of PopperPlacementType
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default RichTooltip
