import {DUMMY_EVENT_ID} from './CalendarWithTooltip'
import TimeBlockOffForm from '../Bookings/TimeBlockOffForm'
import VendorAppointmentForm from '../Bookings/VendorAppointmentForm'
import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import useServiceAvailabilityContext from '../../hooks/useServiceAvailabilityContext'

// This is a highly specialized component that is used to render the form for editing an event / time block off

function EventMenuRenderer({
  obj,
  id,
  onUpdateEvent,
  onCloseEvent,

  editingEvent,
  editingClosure,

  handleUpdateClosure,
  handleUpdateAppointment,
  resetAppointmentEvents,
  resetClosureEvents,

  getColumnStartAndEndTimes,
}) {
  const classes = useStyles()

  const {closuresLookup} = useServiceAvailabilityContext()

  let component = null

  if (!id) {
    // if no ID is passed, it means they've stopped focusing on an event
    // so we want to wipe and changes we made to our events array
    if (editingEvent.current) {
      resetAppointmentEvents()
      editingEvent.current = null
    }
    if (editingClosure.current) {
      resetClosureEvents()
      editingClosure.current = null
    }
    return null
  } else if (DUMMY_EVENT_ID === id) {
    component = (
      <TimeBlockOffForm
        onSaveComplete={() => {
          editingClosure.current = null
          onCloseEvent()
        }}
        onChange={d => {
          onUpdateEvent({...obj, ...getColumnStartAndEndTimes(d)})
        }}
        closureData={obj}
        onCancel={onCloseEvent}
      />
    )
  } else if (closuresLookup[id]) {
    component = (
      <TimeBlockOffForm
        onSaveComplete={() => {
          editingClosure.current = null
          onCloseEvent()
        }}
        onChange={handleUpdateClosure}
        closureData={closuresLookup[id]}
        onCancel={onCloseEvent}
      />
    )
  } else {
    component = (
      <VendorAppointmentForm
        appointmentId={id}
        onUpdateForm={handleUpdateAppointment}
        onCancel={onCloseEvent}
        onSaveComplete={() => {
          editingEvent.current = null
          onCloseEvent()
        }}
      />
    )
  }

  return (
    <div className={classes.formContainer} key={id}>
      {component}
    </div>
  )
}

export default EventMenuRenderer

export const useStyles = makeStyles(theme => ({
  formContainer: {
    maxWidth: '20vw',
  },
}))
