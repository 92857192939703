import ActionTypes from './types'
import ORM from '../../orm/collections'

/**
 * @param {function} dispatch
 * @param {string} vendorId
 */
export async function setUserAction(dispatch, vendorId) {
  if (!vendorId) {
    dispatch({
      type: ActionTypes.SET_USER,
      payload: {
        vendorId: null,
        buildings: [],
        services: [],
      },
    })

    return
  }

  const myBuildings = await ORM.BuildingStaff.as(ORM.BuildingStaff.WithBuilding).listBuildingStaffByStaff({staffId: vendorId})

  if (!myBuildings.length) {
    dispatch({
      type: ActionTypes.SET_USER,
      payload: {
        vendorId: null,
        buildings: [],
        services: [],
      },
    })

    return
  }

  // this assumes all buildings have the same services?
  const myServices = (
    await Promise.all(myBuildings.map(b => ORM.VendorService.listVendorServicesByBuilding({buildingId: b.building.id})))
  ).reduce((acc, services) => {
    return acc.concat(services)
  }, [])

  dispatch({
    type: ActionTypes.SET_USER,
    payload: {
      vendorId: vendorId,
      buildings: myBuildings.map(b => b.building),
      services: myServices,
    },
  })
}
