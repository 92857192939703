import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import EmptyStateIconAndMessage from './EmptyStateIconAndMessage'
import SimpleSpinner from './SimpleSpinner'

const useStyles = makeStyles(theme => ({}))

function LoadingStateIconAndMessage(props) {
  const classes = useStyles()

  return <EmptyStateIconAndMessage message={props.message || 'Loading data'} icon={<SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />} />
}

LoadingStateIconAndMessage.propTypes = {
  message: PropTypes.string,
}

export default LoadingStateIconAndMessage
