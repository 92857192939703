import React, {useEffect, useState, forwardRef} from 'react'
import useDraggable from '../hooks/useDraggable'
import {Colors, Spacing} from '../../assets/styles'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import CalendarEventDragHandle from './CalendarEventDragHandle'
import {constructClassString} from '../../Utils/objectUtil'

/**
 * @typedef CalendarEventConfig
 * @property {string} id
 * @property {string} column
 * @property {number} start
 * @property {number} end
 * @property {*?} content
 * @property {string?} color
 * @property {boolean?} isDisabled
 * @property {boolean?} isDragDisabled
 * @property {number?} _overlapsBefore
 * @property {number?} _overlapsAfter
 */

const CalendarEvent = forwardRef((props, ref) => {
  const classes = eventStyles()
  const [translate, setTranslate] = useState({})
  const [topOffset, setTopOffset] = useState(0)
  const [bottomOffset, setBottomOffset] = useState(0)
  const {isDragging, ref: dragRef} = useDraggable({
    onDrag: (pos, isComplete) => {
      if (isComplete) {
        props.onMove(pos)
        setTranslate({x: 0, y: 0})
      } else {
        setTranslate(pos)
      }
    },
    onClick: props.onClick,
  })

  const isDraggable = !props.event.isDragDisabled

  const style = props.style ? {...props.style} : {}
  if (props.event.color) {
    style.backgroundColor = props.event.color
  }
  style.left = `${props.event._overlapsBefore * Spacing.spaceMedium}px`
  style.width = `calc(100% - ${(props.event._overlapsAfter + props.event._overlapsBefore) * Spacing.spaceMedium}px)`
  if (isDragging && isDraggable) {
    style.transform = `translate(${translate.x}px,${translate.y}px)`
  }

  if (topOffset) {
    style.top = (style.top || 0) + topOffset
    style.height = (style.height || 0) - topOffset
  }

  if (bottomOffset) {
    style.height = (style.height || 0) + bottomOffset
  }

  const handleExpandUp = (pos, isComplete) => {
    if (isComplete) {
      props.onMove({top: pos.y})
      setTopOffset(0)
    } else {
      setTopOffset(pos.y)
    }
  }

  const handleExpandDown = (pos, isComplete) => {
    if (isComplete) {
      props.onMove({bottom: pos.y})
      setBottomOffset(0)
    } else {
      setBottomOffset(pos.y)
    }
  }

  return (
    <div
      className={constructClassString(
        {dragging: isDragging && isDraggable, focused: props.isFocused, disabled: props.event.isDisabled},
        classes.eventContainer,
      )}
      style={style}
      ref={ref}
    >
      {!props.event.isDisabled && isDraggable && <CalendarEventDragHandle onDrag={handleExpandUp} />}
      <div ref={props.event.isDisabled ? () => {} : dragRef} className={classes.content}>
        {props.event.content}
      </div>
      {!props.event.isDisabled && isDraggable && <CalendarEventDragHandle onDrag={handleExpandDown} />}
    </div>
  )
})

CalendarEvent.propTypes = {
  event: PropTypes.any, // CalendarEventConfig
  onClick: PropTypes.func,
  onMove: PropTypes.func,
  style: PropTypes.any,
  isFocused: PropTypes.bool,
}

const focusedStyleOverrides = {
  zIndex: 10,
  left: `0 !important`,
  width: `100% !important`,
}

const eventStyles = makeStyles(theme => ({
  eventContainer: {
    userSelect: 'none',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 8,
    border: `1px solid ${Colors.rxrWhiteColor}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.rxrGreyColor,
    zIndex: 1,
    transition: `left 0.2s ease-in-out, width 0.2s ease-in-out, box-shadow 0.2s ease-in-out, margin 0.2s ease-in-out`,
    cursor: 'pointer',
    overflow: 'hidden',
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 0%)',

    '&:hover': focusedStyleOverrides,
    '&.focused': focusedStyleOverrides,
    '&.disabled': {backgroundColor: Colors.rxrMediumLightGreyColor, cursor: 'not-allowed'}, // default disabled color

    '&.dragging': {
      borderColor: 'transparent',
      cursor: 'move',
      boxShadow: '0px 5px 10px 5px rgb(0 0 0 / 20%)',
      marginTop: `-6px`,
    },
  },

  content: {
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${Spacing.spaceSmall}`,
    color: Colors.rxrWhiteColor,
  },
}))

export default CalendarEvent
