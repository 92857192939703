import {Auth, Storage} from 'aws-amplify'
import awsConfig from '../../../src/amplifyconfiguration.json'
import amplifyORM from '../../orm/collections'
import SimpleAppSyncClient from './SimpleAppSyncClient'
import FileHelper from './FileHelper'

Auth.configure(awsConfig)

class AppAuth {
  initedModels = false
  authedUserId = null

  /** @type {AppAuth} */
  static #instance
  /**
   * @constructor
   */
  constructor() {
    if (AppAuth.#instance) {
      throw new Error('AppAuth is a Singleton')
    }

    if (!awsConfig) {
      throw new Error('Initialization of AppAuth requires a config')
    }

    this.initedModels = false
    FileHelper.Instance(Storage, awsConfig)
    AppAuth.#instance = this
  }

  /**
   * @returns {Promise<CognitoUser|null>}
   */
  async getAuthenticatedUser() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser && !cognitoUser.expired) {
        if (!this.initedModels) {
          amplifyORM.init(async () => {
            const u = await Auth.currentAuthenticatedUser()
            this.authedUserId = u.attributes.sub
            return new SimpleAppSyncClient({
              url: awsConfig.aws_appsync_graphqlEndpoint,
              region: awsConfig.aws_appsync_region,
              jwtToken: u.signInUserSession.idToken.jwtToken,
            })
          })

          this.initedModels = true
        }
        return cognitoUser
      }
    } catch (error) {
      console.error('error inside of getAuthenticatedUser:', error)
    }
    return null
  }

  /**
   * @param {string} loginNum
   * @param {string} temporaryPassword
   * @returns {Promise<null>}
   */
  async signUp(loginNum, temporaryPassword) {
    await Auth.signUp({
      username: loginNum,
      password: temporaryPassword,
      attributes: {
        email: '',
      },
      validationData: [],
    })
  }

  /**
   * @param {string} loginNum
   * @returns {Promise<null>}
   */
  async forgotPassword(loginNum) {
    await Auth.forgotPassword(loginNum)
  }

  /**
   * @param {string} loginNum
   * @returns {Promise<*>}
   */
  async resendSignUp(loginNum) {
    return await Auth.resendSignUp(loginNum)
  }

  /**
   * @param {string} loginNum
   * @param {string} code
   * @returns {Promise<*>}
   */
  async confirmSignUp(loginNum, code) {
    return await Auth.resendSignUp(loginNum, code, {
      forceAliasCreation: false,
    })
  }

  /**
   * @param {string} loginNum
   * @param {string} password
   * @returns {Promise<*>}
   */
  async signIn(loginNum, password) {
    await Auth.signIn(loginNum, password)
    return await this.getAuthenticatedUser()
  }

  /**
   * @param {string} phoneNumber
   * @param {string} code
   * @param {string} temporaryPassword
   * @returns {Promise<*>}
   */
  async forgotPasswordSubmit(phoneNumber, code, temporaryPassword) {
    return await Auth.forgotPasswordSubmit(phoneNumber, code, temporaryPassword)
  }

  /**
   * @returns {Promise<void>}
   */
  async logOut() {
    this.initedModels = false
    this.authedUserId = null
    await Auth.signOut()
  }

  // ---------------------------------------------------------------
  // STATIC FUNCTIONS:

  /**
   * @returns {AppAuth}
   */
  static Instance() {
    if (!AppAuth.#instance) {
      new AppAuth()
    }
    return AppAuth.#instance
  }
}

AppAuth.LoginError = class extends Error {
  constructor(message) {
    super(message)
    this.name = 'LoginError'
  }
}

AppAuth.VendorError = class extends AppAuth.LoginError {
  constructor(message) {
    super(message)
    this.name = 'VendorError'
  }
}

AppAuth.ConfigurationError = class extends Error {
  constructor(message) {
    super(message)
    this.name = 'ConfigurationError'
  }
}

export default AppAuth
