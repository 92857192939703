import React from 'react'
import Switch from '@mui/material/Switch'
import PropTypes from 'prop-types'

export default function DashboardToggle(props) {
  let val = !!props.value
  return (
    <div>
      <Switch
        checked={val}
        onChange={e => props.onChange(e.target.checked)}
        color="primary"
        name="active"
        inputProps={{'aria-label': 'primary checkbox'}}
        disabled={props.isDisabled}
      />

      {props.label &&
        (!props.isDisabled ? (
          <span style={{cursor: 'pointer'}} onClick={e => props.onChange(!val)}>
            {props.label}
          </span>
        ) : (
          <span>{props.label}</span>
        ))}
    </div>
  )
}

DashboardToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
}
