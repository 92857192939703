import {spaceMedium} from './spacing'
import {rxrWhiteColor} from './color'

export const formContainer = {
  backgroundColor: rxrWhiteColor,
  borderRadius: '0px 12px 12px 0px',
}

export const KYRScreenLayout = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '419px',
  marginTop: '8px',
}

export const KYRScreenLayoutDashboard = {
  height: 'auto',
  marginTop: '8px',
}

export const KYRLoaderScreen = {
  paddingTop: '120px',
  backgroundColor: 'transparent ',
  opacity: 1,
}

export const gutter = {
  width: spaceMedium,
  height: spaceMedium,
  flexShrink: 0,
}

export const PageContainer = {
  position: 'relative',
  height: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
}
