import React, {useState, useEffect} from 'react'
import {makeStyles} from '@mui/styles'
import {Colors} from './assets/styles'
import RouteConstants from './constants/routes'
import {Switch, Route, useLocation, matchPath} from 'react-router-dom'
import useNavigate from './hooks/useNavigateShim'
import CenteredSpinner from './components/CenteredSpinner'
import NavPanel, {MobileNavClosedHeight} from './components/NavPanel'
import {useSelector, useDispatch} from 'react-redux'
import CalendarPage from './components/Calendar/CalendarPage'
import BackgroundDataSync from './components/BackgroundDataSync'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3' // this relies on package 'date-fns'
import {enUS} from 'date-fns/locale/en-US'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {useTheme} from '../../src/theme'
import AppAuth from './lib/AppAuth'
import {setUserAction} from './actions/userActions'
import Login from './components/Login/Login'
import VendorBookings from './components/Bookings/VendorBookings'

const useStyles = makeStyles(theme => ({
  interfaceContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    height: '100%',
    background: Colors.rxrLightGreyColor,
    overflowY: 'auto',
    paddingTop: 0,
  },
}))

function App() {
  const [isReady, setIsReady] = useState(false)
  const userId = useSelector(state => state.User.vendorId)
  const currentLocation = useLocation()
  const pathname = currentLocation.pathname
  const isMobile = useSelector(state => state.App.isMobile)
  const navPanelWidth = useSelector(state => state.App.navPanelWidth)
  const classes = useStyles({navPanelWidth, isMobile})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    //check if there is a currently authenticated user
    AppAuth.Instance()
      .getAuthenticatedUser()
      .then(cognitoUser => {
        if (cognitoUser) {
          return setUserAction(dispatch, cognitoUser.attributes.sub)
        }
      })
      .then(() => {
        setIsReady(true)
      })
  }, [])

  // Handles routing to appropriate path based on authentication status
  useEffect(() => {
    if (!isReady) {
      return
    }

    const loginMatch = matchPath(pathname, {path: RouteConstants.LOGIN})
    const dashboardRootMatch = matchPath(pathname, {path: RouteConstants.DASHBOARD_ROOT, exact: true})

    if (userId && (loginMatch || dashboardRootMatch)) {
      navigate(RouteConstants.BOOKINGS)
    } else if (!userId && pathname !== RouteConstants.LOGIN) {
      navigate(RouteConstants.LOGIN)
    }
  }, [userId, isReady, pathname])

  useEffect(() => {
    // TODO: check auth, redirect appropriately
    setTimeout(() => {
      setIsReady(true)
    }, 1000)
  }, [])

  const [currentTheme, setCurrentTheme] = useTheme()

  if (!isReady) {
    return <CenteredSpinner />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={currentTheme}>
          <div className={classes.interfaceContainer}>
            {!!userId && (
              <React.Fragment>
                <NavPanel />
                <BackgroundDataSync />
              </React.Fragment>
            )}
            <div className={classes.content} style={isMobile ? {paddingTop: MobileNavClosedHeight} : undefined}>
              {/*
              We're using a switch here because we only ever want one to render at a time.
              So the first matching route it finds will be the one that is displayed.
              Because of this, the order is important.
              For best results: place more descriptive routes above less descriptive routes
              For readability: please keep similar routes grouped together
              */}
              <Switch>
                {/* ROOT ----------------------------------------------------------------- */}
                {/* This will get replaced by either Login or the Bookings route depending on the user's Auth status */}
                <Route exact path={RouteConstants.DASHBOARD_ROOT} render={() => <CenteredSpinner />} />

                {/* LOGIN ----------------------------------------------------------------- */}
                <Route path={RouteConstants.LOGIN} render={() => <Login />} />

                {/* BOOKINGS ----------------------------------------------------------------- */}
                <Route path={RouteConstants.BOOKINGS} render={() => <VendorBookings />} />

                {/* CALENDAR ----------------------------------------------------------------- */}
                <Route exact path={RouteConstants.CALENDAR} render={() => <CalendarPage />} />

                {/* SERVICE MANAGER ----------------------------------------------------------------- */}
                <Route exact path={RouteConstants.SERVICE_MANAGER} render={() => <div>Service Manager</div>} />
              </Switch>
            </div>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

export default App
