import React from 'react'
import {makeStyles} from '@mui/styles'
import {IconButton} from '@mui/material'
import {rxrWhiteColor, rxrDarkGreyColor, rxrMediumGreyColor} from '../assets/styles/color'
import RXRIcon from './RXRIcon'
import {iconButton} from '../assets/styles/buttons'
import PropTypes from 'prop-types'
import TextInput from './TextInput'

const useStyles = makeStyles(theme => ({
  closeIconButton: {
    ...iconButton,
    color: rxrMediumGreyColor,
    '&:hover': {
      color: rxrDarkGreyColor,
    },
    position: 'absolute',
    top: 10,
    right: 4,
  },
  container: {
    width: '100%',
    backgroundColor: rxrWhiteColor,
    position: 'relative',
  },
}))

export default function ClearableInput(props) {
  const inputContent = props.value || ''
  const hasContent = inputContent.length > 0

  const classes = useStyles()

  const handleClear = () => {
    props.onChange('')
  }

  return (
    (<div className={classes.container}>
      <TextInput placeholder={props.placeholder} value={inputContent} onChange={props.onChange} />
      {hasContent && (
        <IconButton className={classes.closeIconButton} onClick={handleClear} size="large">
          <RXRIcon icon={RXRIcon.CLOSE} />
        </IconButton>
      )}
    </div>)
  );
}

ClearableInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}
