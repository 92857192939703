import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class Occupancy extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getOccupancy(input, options) {
    return this._get(
       'getOccupancy',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getOccupancy(input, options) {
    return new this().getOccupancy(input, options)
  }

  /**
   * @param { {filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listOccupancys(input, options) {
    return this._list(
       'listOccupancys',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listOccupancys(input, options) {
    return new this().listOccupancys(input, options)
  }

  /**
   * @param { {filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateOccupancys(input, options) {
    return this._iterate(
       'listOccupancys',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateOccupancys(input, options) {
    return new this().iterateOccupancys(input, options)
  }

  /**
   * @param { {yardiId?: String, status?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listOccupancysByYardiId(input, options) {
    return this._list(
       'listOccupancysByYardiId',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {yardiId?: String, status?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listOccupancysByYardiId(input, options) {
    return new this().listOccupancysByYardiId(input, options)
  }

  /**
   * @param { {yardiId?: String, status?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateOccupancysByYardiId(input, options) {
    return this._iterate(
       'listOccupancysByYardiId',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {yardiId?: String, status?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelOccupancyFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateOccupancysByYardiId(input, options) {
    return new this().iterateOccupancysByYardiId(input, options)
  }

  /**
   * @param { {input: CreateOccupancyInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createOccupancy(input, options) {
    return this._mutate(
       'createOccupancy',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateOccupancyInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createOccupancy(input, options) {
    return new this().createOccupancy(input, options)
  }

  /**
   * @param { {input: UpdateOccupancyInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateOccupancy(input, options) {
    return this._mutate(
       'updateOccupancy',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateOccupancyInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateOccupancy(input, options) {
    return new this().updateOccupancy(input, options)
  }

  /**
   * @param { {input: DeleteOccupancyInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteOccupancy(input, options) {
    return this._mutate(
       'deleteOccupancy',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteOccupancyInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteOccupancy(input, options) {
    return new this().deleteOccupancy(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "Occupancy"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    Object.entries(Occupancy._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = Occupancy.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })

    // automatically JSON Parse all AWSJSON fields
    Occupancy._jsonFields.forEach(field => {
      if (Object.hasOwnProperty.call(obj, field) && typeof obj[field] === 'string') {
        obj[field] = JSON.parse(obj[field])
      }
    })

    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof Occupancy.custom_afterFind === 'function') {
      return Occupancy.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { Occupancy }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
Occupancy._complexFields = {"unit":"Unit","residents":"[Resident]","cleaningRequests":"[CleaningRequest]","disabledFeatures":"[CoreFeature]","ledgerItems":"[LedgerItem]","onboardingTasks":"[OnboardingTask]"}
Occupancy._jsonFields = ["onboardingMeta",]

Occupancy.FragmentDefault = `
fragment OccupancyFragmentDefault on Occupancy {
  id
  createdAt
  status
  leaseFromDate
  occupancyUnitId
  yardiId
  mustUpdateLedger
  leaseToDate
  onboardingMeta
  ledgerLastUpdatedAt
  updatedAt
}

`


export default Occupancy
