import React from 'react'
import {makeStyles} from '@mui/styles'
import {Spacing, Typography} from '../assets/styles'
import RXRButton from './RXRButton'

function ErrorBoundaryFallback(props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div>
        <h2 className={classes.title}>Uh oh!</h2>
        <p className={classes.message}>
          It looks like something went wrong. Please refresh your screen and try again. If you're seeing this error repeatedly, please
          contact your property team for assistance. We apologise for any inconvenience.
        </p>
      </div>
      <RXRButton onClick={props.resetErrorBoundary}>Refresh page</RXRButton>
      <p className={classes.error}>ERROR: "{props.error?.message}"</p>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
  },

  title: {
    fontSize: Typography.fontSizeExtraLarge,
    marginBottom: Spacing.spaceMedium,
  },

  message: {
    width: '80%',
    margin: '0 auto',
  },

  error: {
    position: 'absolute',
    opacity: 0.8,
    fontStyle: 'italic',
    bottom: Spacing.spaceMedium,
  },
}))

export default ErrorBoundaryFallback
