import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import {makeStyles} from '@mui/styles'
import {Typography, Colors, boxShadowSmall, Spacing, constructClassString} from '../../assets/styles'
import 'react-phone-number-input/style.css'
import {getCountryCallingCode, getCountries, isValidPhoneNumber} from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en'
import PhoneInput from 'react-phone-number-input/input'
import PropTypes from 'prop-types'
import RXRIcon from '../RXRIcon'
import {useMediaQuery, useTheme} from '@mui/material'

const borderRadius = 6
const inputHeight = 42

const useStyles = makeStyles(theme => ({
  phoneNumberInputCopy: {
    marginTop: Spacing.spaceLarge,
    textAlign: 'center',
    fontSize: Typography.fontSizeMedium,
  },
  phoneNumberInputContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: Spacing.spaceExtraLarge,
    position: 'relative',
    maxWidth: 400,
    margin: '0 auto',

    '& input': {
      flex: 1,
      display: 'block',
      paddingLeft: `${Spacing.spaceSmall}px !important`,
      border: `1px solid ${Colors.rxrMediumGreyColor}`,
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      fontSize: Typography.fontSizeMediumLarge,
      outline: 0,
      height: `${inputHeight}px !important`,
      width: 'auto !important',

      [theme.breakpoints.down('sm')]: {
        fontSize: Typography.fontSizeMedium,
      },
    },
  },
  dash: {
    marginRight: Spacing.spaceExtraSmall,

    [theme.breakpoints.down('md')]: {
      marginRight: Spacing.spaceExtraExtraSmall,
    },

    [theme.breakpoints.down('sm')]: {
      marginRight: 1,
    },
  },

  disclaimer: {
    textAlign: 'center',
    fontSize: Typography.fontSizeSmall,
    margin: `${Spacing.spaceLarge}px 0`,
  },

  countryContainer: {
    '&.open': {
      '& $countrySelect': {
        display: 'block',
      },
    },
  },

  flagAndCode: {
    display: 'flex',
    '& img': {
      width: 20,
    },

    '& span': {
      marginLeft: Spacing.spaceExtraSmall,
      '&:nth-child(3)': {
        color: Colors.rxrMediumGreyColor,
      },
    },
  },

  countryInput: {
    flexShrink: 0,
    width: 100,
    height: `${inputHeight}px !important`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: Spacing.spaceExtraSmall,
    border: `1px solid ${Colors.rxrMediumGreyColor}`,
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    borderRight: 0,

    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: Colors.rxrLightGreyColor,
    },

    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
  },

  countrySelect: {
    boxShadow: boxShadowSmall,
    position: 'absolute',
    top: -60,
    left: 0,
    zIndex: 2,
    display: 'none',
    overflow: 'hidden',
    border: `1px solid ${Colors.rxrMediumGreyColor}`,
    borderRadius: 6,
    backgroundColor: Colors.rxrWhiteColor,
  },

  countrySelectInner: {
    width: '100%',
    height: 200,
    overflowY: 'scroll',
  },

  countryOption: {
    padding: `${Spacing.spaceExtraExtraSmall}px ${Spacing.spaceSmall}px`,
    backgroundColor: Colors.rxrWhiteColor,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: Colors.rxrLightGreyColor,
    },
  },
}))

function PhoneNumberInput(props) {
  const classes = useStyles()
  const [number, setNumber] = useState('')
  const [country, setCountry] = useState('US')

  useEffect(() => {
    if (!number || !isValidPhoneNumber(number)) {
      return
    }

    props.onSubmit(number)
  }, [number])

  return (
    <div className={classes.phoneNumberInputComponent}>
      <p className={classes.phoneNumberInputCopy}>Please type in your phone number to get started.</p>
      <div className={classes.phoneNumberInputContainer}>
        <CountrySelect onChange={setCountry} country={country} />
        <PhoneInput
          placeholder="Enter phone number"
          value={number}
          country={country}
          // withCountrySelect={true}
          onChange={setNumber}
        />
      </div>

      {!props.hideDisclaimer && (
        <p className={classes.disclaimer}>
          By typing in your phone number, you agree to receive a one time password by SMS to the number provided.
        </p>
      )}
    </div>
  )
}

PhoneNumberInput.propTypes = {
  hideDisclaimer: PropTypes.bool,
  onSubmit: PropTypes.func,
  setError: PropTypes.func,
}

export default PhoneNumberInput

// -----------------------------------------------------------------

function FlagAndCode(props) {
  const classes = useStyles()

  return (
    <div className={classes.flagAndCode}>
      <img src={getFlagIcon(props.country)} alt={`${en[props.country]} flag`} />
      {props.includeCountry && <span>{en[props.country]}</span>}
      <span>+{getCountryCallingCode(props.country)}</span>
    </div>
  )
}
FlagAndCode.propTypes = {
  country: PropTypes.string,
  includeCountry: PropTypes.bool,
}

// sort US to the top, then alphabetically
const countriesSorted = getCountries().sort((a, b) => (a === 'US' ? -1 : en[a].localeCompare(en[b])))

function CountrySelect(props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useLayoutEffect(() => {
    const handleClick = ev => {
      if (ev.target !== ref.current) {
        setIsOpen(false)
      }
    }

    window.document.addEventListener('click', handleClick)
    return () => {
      window.document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div className={constructClassString(classes.countryContainer, {open: isOpen})}>
      <div
        onClick={ev => {
          ev.stopPropagation()
          setIsOpen(!isOpen)
        }}
        className={classes.countryInput}
      >
        <FlagAndCode country={props.country} />
        {!isMobile && <RXRIcon icon={isOpen ? RXRIcon.ARROW_UP : RXRIcon.ARROW_DOWN} size={RXRIcon.SIZE_SMALL} />}
      </div>
      <div className={classes.countrySelect} ref={ref}>
        <div className={classes.countrySelectInner}>
          {countriesSorted.map(country => (
            <div
              className={classes.countryOption}
              key={country}
              onClick={ev => {
                props.onChange(country)
                setIsOpen(false)
              }}
            >
              <FlagAndCode country={country} includeCountry={true} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function getFlagIcon(countryCode) {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`
}

CountrySelect.propTypes = {
  country: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
