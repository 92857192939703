import {useSelector} from 'react-redux'
import {useRef} from 'react'

const BUILDING_COLORS = [
  '#9977D2',
  '#52AD83',
  '#DB8888',
  '#19a5b8',
  '#ff6e61',
  '#d87c2c',
  '#8aceff',
  '#f39bc4',
  '#f1b350',
  '#1a8952',
  '#fd4fa3',
  '#2cd871',
  '#ff4c4c',
  '#7152ce',
  '#9b0199',
  '#808d28',
  '#7caf99',
  '#f4a460',
]

/**
 * @returns {function(string): string}
 */
function useBuildingColors() {
  const buildingIds = useSelector(state => state.User.buildingIds)
  const buildingColorMap = useRef(null)
  if (!buildingColorMap.current) {
    buildingColorMap.current = {}
    buildingIds.forEach((id, index) => {
      // if we see black it means we've extended beyond our building colors array
      buildingColorMap.current[id] = BUILDING_COLORS[index] || '#000000'
    })
  }

  return id => {
    return buildingColorMap.current[id] || '#000000'
  }
}

export default useBuildingColors
