import SimpleSpinner from './SimpleSpinner'
import React from 'react'
import {Colors, constructClassString, Spacing} from '../assets/styles'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'

function CenteredSpinner(props) {
  const classes = useStyles()
  return (
    <div className={constructClassString(classes.savingContainer, {[classes.loadingOverlay]: props.isOverlay}, props.className)}>
      <div>
        <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        <p>{props.message}</p>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  savingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50%',
    textAlign: 'center',

    '& p': {
      marginTop: Spacing.spaceSmall,
    },
  },

  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: Colors.whiteOverlay50,
    backdropFilter: 'blur(3px)',
    zIndex: 2,
  },
}))

CenteredSpinner.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  isOverlay: PropTypes.bool,
}

export default CenteredSpinner
