import {print} from 'graphql'

/**
 * @typedef AppSyncClientConstructor
 * @property {string} url
 * @property {string} jwtToken
 * @property {string?} region
 * @property {boolean?} debug
 */

/**
 * @typedef GraphQLQueryPayload
 * @property {*} query
 * @property {*} variables
 */

/**
 * @typedef GraphQLMutatePayload
 * @property {*} mutation
 * @property {*?} query
 * @property {*} variables
 */

// Currently only supports AMAZON_COGNITO_USER_POOLS authentication
class AppSyncClient {
  /**
   * @param {AppSyncClientConstructor} config
   */
  constructor(config) {
    this.url = config.url
    this.jwtToken = config.jwtToken
    this.region = config.region
    this.debug = !!config.debug
  }

  /**
   * @param {GraphQLQueryPayload} payload
   * @returns {Promise<*>}
   */
  async query(payload) {
    return this._send({...payload, query: print(payload.query)})
  }
  /**
   * @param {GraphQLMutatePayload} payload
   * @returns {Promise<*>}
   */
  async mutate(payload) {
    return this._send({
      ...payload,
      query: print(payload.mutation || payload.query),
    })
  }

  /**
   * @param {*} payload
   * @returns {Promise<*>}
   * @private
   */
  async _send(payload) {
    if (this.debug) {
      console.log(`Sending graphql payload:`, payload)
    }

    const options = {
      method: 'POST',
      headers: {
        Authorization: this.jwtToken,
      },
      body: JSON.stringify(payload),
    }

    const request = new Request(this.url, options)

    const response = await fetch(request)
    try {
      const retVal = await response.json()

      if (this.debug) {
        console.log(retVal)
      }

      // this is how the aws-appsync client works. The response will be 200, but will contain an errors payload
      // if present, it throws the entire response.
      if (retVal && retVal.errors) {
        throw retVal
      }

      return retVal
    } catch (err) {
      if (this.debug) {
        console.error(err)
      }
      throw err
    }
  }
}

export default AppSyncClient
