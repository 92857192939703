export default {
  VendorAppointment: {
    /**
     * @param {*} obj
     * @returns {AppointmentData}
     */
    afterFind: obj => {
      obj.startAt = new Date(obj.startAt)
      obj.endAt = new Date(obj.endAt)
      obj.addOns = obj.addOns || []

      // copy this to the root resident object for simplicity
      obj.resident.unitNumber = obj.resident.occupancy.unit.number
      delete obj.resident.occupancy

      // make sure they have a string as an email
      obj.resident.email = (obj.resident.email || '').toLowerCase()

      return obj
    },
  },

  VendorService: {
    afterFind: obj => {
      if (obj.type === 'Cleaning') {
        const bedCount = parseInt(obj.subType)
        obj.bedLabel = bedCount === 0 ? 'Studio' : `${bedCount} bed`
      } else {
        obj.bedLabel = ''
      }

      return obj
    },
  },
}
